/* 
        Project             : eSafety 2.0
        Client              : Internal
        Author Company      : ESA
        Author              : Peter Ross
        Contact             : pross@esassoc.com

*/
import { Button, Dialog, TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import Save from "@material-ui/icons/SaveAlt";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import bootstrapFromAPI from "../../bootstrapData";
import { addScope } from "../../redux/reducers/ticketReducer";
import { ticketStyles } from "../../Styles";
import { tickTemplate } from "./tickTemplate";

export default function TicketScope(props) {
	const project = useSelector((state) => state.ProjectReducer.projectInfo);
	const reviewMode = useSelector((state) => state.ProjectReducer.reviewMode);
	const userObj = useSelector((state) => state.AuthenReducer.userObj);
	const [activeDiag, setActiveDiag] = useState(false);
	const [value, setValue] = useState();
	const dispatch = useDispatch();
	const classes = ticketStyles();
	const [, setState] = useState({});
	const [isVisable, setIsVisable] = useState(false);

	const commentChange = (a) => {
		setValue(a.target.value);
	};

	useEffect(() => {
		const pReviews = project.projectReviews
			? project.projectReviews.filter(
					(a) => (a.reviewer ? a.reviewer.userName.toLowerCase() : "not valid") === userObj.user.mail.toLowerCase(),
				)
			: [];
		if (pReviews.length > 0 && reviewMode) {
			setIsVisable(true);
		}

		dispatch(addScope(props.scope));
	}, [dispatch, props.scope, project, userObj, reviewMode]);

	function saveComment() {
		var newTicket = {
			...tickTemplate,
			comment: value,
			userId: userObj.user.mail,
			userName: userObj.user.displayName,
			ticketScope: props.scope,
			projectId: project.projectId,
		};
		//Should reset ui to a blank comment.
		setValue("");

		project.tickets.push(newTicket);
		var newProject = { ...project };
		bootstrapFromAPI.saveProject(newProject);
		setState({});
		if (props.saveCallBack) props.saveCallBack(newTicket);
	}

	return (
		<>
			<Dialog open={activeDiag}>
				<Paper
					style={{ padding: 10 }}
					elevation={10}
					width={600}>
					{" "}
					<TextField
						id="new-ticket-comment-field"
						label="New Comment"
						multiline
						fullWidth={true}
						maxRows={8}
						value={value}
						onChange={commentChange}
						variant="outlined"
					/>
					<Button onClick={() => setActiveDiag(false)}>
						<Close />
						Close
					</Button>{" "}
					<Button
						onClick={() => {
							saveComment();
							setActiveDiag(false);
						}}>
						<Save />
						Save
					</Button>
				</Paper>
			</Dialog>
			{isVisable ? (
				<Paper
					className={classes.selectedScope}
					elevation={0}>
					<div style={{ textAlign: "right", display: "flex", width: "100pct", color: "#75BFB8" }}>
						{" "}
						<Button
							style={{ color: "#75BFB8" }}
							onClick={() => setActiveDiag(true)}>
							<AddIcon style={{ color: "#75BFB8" }} /> Add Comment
						</Button>{" "}
					</div>
					{props.children}
				</Paper>
			) : (
				<>{props.children}</>
			)}
		</>
	);
}
