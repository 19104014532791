import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FieldStaff from "../../Control/FieldStaff.Component";
import { StepThreeStyles } from "../../../Styles";
import { clearScopes } from "../../../redux/reducers/ticketReducer";
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../../redux/actions";
import { user } from "../../../models/user";
import TicketScope from "../../Ticket/ticketScope";
import VersionCheck from "../../Control/VersionCheck";
import { RotateSpinner } from "react-spinners-kit";

const StepThree = () => {
	const [, setState] = useState();
	const [loaded, setLoaded] = useState(false);
	const currentPsp = useSelector((state) => state.ProjectReducer);
	const pm = currentPsp.projectInfo.projectUsers.find((usr) => usr.pspRoleId === 1);
	const isReadOnly = useSelector((state) => state.ProjectReducer.isReadOnly);
	let delteckUsers = useSelector((state) => state.DeltekUserListReducer.deltekUserList);
	const classes = StepThreeStyles();
	const dispatch = useDispatch();

	const [fieldLeader, setFieldLeader] = useState(
		currentPsp.projectInfo.projectUsers.find((user) => user.pspRoleId === 2) === undefined
			? user
			: delteckUsers.find(
					(a) => a.userId === currentPsp.projectInfo.projectUsers.find((user) => user.pspRoleId === 2).userId,
				),
	);

	const [fieldStaff, setFieldStaff] = useState(null);
	const [clearValue, setClearValue] = useState("");

	useEffect(() => {
		dispatch(clearScopes());
		setLoaded(true);
	}, [dispatch]);

	const captureInput = (evtData) => {
		setClearValue(evtData.target.value);
	};
	const handleAddFieldStaff = (event, value) => {
		//Adding field staff to the array
		setFieldStaff(null);
		setState({});

		if (value !== undefined && value !== null) {
			value.pspRoleId = 3;
			const fieldStaff = value;
			currentPsp.projectInfo.projectUsers
				.filter((user) => user.pspRoleId === 3)
				.find((user) => user.userId === fieldStaff.userId)
				? console.log("User Already Exist")
				: currentPsp.projectInfo.projectUsers.push(fieldStaff);
			dispatch(allActions.ProjectActions.saved_Project(currentPsp.projectInfo));
			//Clear out option box.
			document.body.focus();
			setClearValue("");
		}
	};

	//Add or replace field leader
	const handleAddFieldLeader = (event, value) => {
		//Adding field staff to the array
		if (value !== undefined && value !== null) {
			value.pspRoleId = 2;
			setFieldLeader(value);
			const existingLeader = currentPsp.projectInfo.projectUsers.find((user) => user.pspRoleId === 2);
			if (existingLeader === undefined) {
				currentPsp.projectInfo.projectUsers.push(value);
			} else {
				currentPsp.projectInfo.projectUsers = currentPsp.projectInfo.projectUsers.filter(
					(user) => user.pspRoleId !== 2,
				);
				currentPsp.projectInfo.projectUsers.push(value);
			}

			dispatch(allActions.ProjectActions.saved_Project(currentPsp.projectInfo));

			setState({});
		}
		if (value === null) {
			currentPsp.projectInfo.projectUsers = currentPsp.projectInfo.projectUsers.filter((user) => user.pspRoleId !== 2);
			dispatch(allActions.ProjectActions.saved_Project(currentPsp.projectInfo));
			setState({});
		}
	};

	//This is just to update the child component
	function updateUi() {
		setState({});
	}

	return !loaded ? (
		<div style={{ padding: "2rem" }}>
			<RotateSpinner
				size={45}
				color="#686769"
			/>
		</div>
	) : (
		<>
			<VersionCheck />
			<div className={classes.root}>
				<Grid
					container
					spacing={3}>
					<Grid
						item
						xs={12}>
						<Card>
							<CardHeader
								className={classes.cardHeader}
								title="Step 3: Project Team"
							/>
							<br />
							<CardContent>
								<TicketScope scope="step3-projectTeam">
									<Typography variant="h6">Team Members</Typography>
									<br />
									<Grid
										container
										spacing={4}>
										<Grid
											item
											xs={6}>
											<TextField
												id="projectManager"
												fullWidth={true}
												label="Project Manager"
												value={pm && pm.fullName}
											/>
										</Grid>
										<Grid
											item
											xs={6}>
											<Autocomplete
												id="fieldLeader"
												options={delteckUsers}
												getOptionLabel={(option) => option.fullName}
												onChange={handleAddFieldLeader}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Field Leader"
													/>
												)}
												value={fieldLeader}
												disabled={isReadOnly}
											/>
										</Grid>
									</Grid>
									<br />
									<hr />
									<Grid
										container
										spacing={1}>
										<Grid
											item
											xs={4}>
											<Autocomplete
												inputValue={clearValue}
												id="fieldStaff"
												options={delteckUsers}
												value={fieldStaff}
												getOptionLabel={(option) => option.fullName}
												onInput={captureInput}
												onChange={handleAddFieldStaff}
												renderInput={(params) => {
													return (
														<>
															{" "}
															<TextField
																{...params}
																placeholder="Type and select to add staff"
																label="Field Staff"
																id="selectedFieldStaff"
															/>
														</>
													);
												}}
												disabled={isReadOnly}
											/>
										</Grid>
										<Grid
											item
											xs={8}>
											<Grid
												container
												spacing={1}
												className={classes.staffFieldContainer}>
												{currentPsp.projectInfo.projectUsers
													.filter((user) => user.pspRoleId === 3)
													.map((fieldStaff) => (
														<Grid
															item
															xs={3}
															key={fieldStaff.userId}>
															<FieldStaff
																Staff={fieldStaff}
																updateUi={updateUi}
															/>
														</Grid>
													))}
											</Grid>
										</Grid>
									</Grid>
								</TicketScope>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default StepThree;
