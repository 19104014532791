import CreateIcon from "@material-ui/icons/Create";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { DataGridPro } from "@mui/x-data-grid-pro";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import bootstrapFromAPI from "../../bootstrapData";
import { projectList } from "../../models/projectList";
import { AcknowledgementStyles } from "../../Styles";
import { API_URI_BASE } from "../../config";
import { iconColumnStyles } from "../../utilities/columnHelpers";

export const PSPSignatureList = () => {
	const pspId = useParams().pspId;
	const wbs1 = useParams().wbs1;
	const history = useHistory();

	// TODO: this is hand coded list - That does not seem right. 3/11 - JS
	const deltekProject = projectList.find((project) => project.wbs1 === wbs1);

	const [signatures, setSignnatures] = useState(undefined);
	const classes = AcknowledgementStyles();
	const [pUsers, setPUsers] = useState(undefined);

	const columns = [
		{ field: "fullName", headerName: "Employee", width: 175 },
		{ field: "userId", headerName: "Email", flex: 0.75 },
		{ field: "pspRoleName", headerName: "Role", flex: 0.5 },
		{ field: "signDate", headerName: "Date", flex: 0.5 },
		{
			...iconColumnStyles,
			hide: false,
			field: "edit",
			headerName: "Acknowledgement",
			flex: 0.5,
			renderCell: (params) => {
				return (
					<Fab
						color="secondary"
						size="small">
						{params.row.signed ? <CheckCircleIcon /> : <CloseIcon />}
					</Fab>
				);
			},
		},
	];

	const gridUsers = () => {
		if (!pUsers || !signatures) return undefined;
		//Return array of project users xref'd with their signatures (or not). Also contains non-project user signatures.
		var retUsers = [];
		pUsers.forEach((a) => {
			//Find user in signature;
			var sig = signatures.filter((b) => b.signatureUser.toLowerCase() === a.userId.toLowerCase());
			var sigDate = sig.length > 0 ? new Date(sig[0].signatureDate).toLocaleDateString() : "Not Signed";
			//var objUser = { ...a, signed: sig.length > 0, signDate: sigDate, id:a.userId, role:a.pspRole.pspRoleName };
			var objUser = {
				...a,
				signed: sig.length > 0,
				signDate: sigDate,
				id: a.userId,
				role: a.pspRoleName,
			};
			retUsers.push(objUser);
		});

		//Now, for testing purposes, let's see if anyone has signed that's not on the project
		signatures.forEach((a) => {
			var usr = pUsers.filter((b) => b.userId.toLowerCase() === a.signatureUser.toLowerCase());
			if (usr.length === 0) {
				var objUser = {
					pspRole: {
						pspRoleName: "Non project member",
					},
					role: "Non project member",
					id: a.signatureUser,
					fullName: a.signatureUserName,
					userId: a.signatureUser,
					signed: true,
					signDate: new Date(a.signatureDate).toLocaleDateString(),
				};
				console.log(retUsers);
				retUsers.push(objUser);
			}
		});

		console.log("gridUsers", retUsers);

		return retUsers;
	};

	useEffect(() => {
		const data = async () => {
			await bootstrapFromAPI.getSignatures(pspId).then((result) => {
				setSignnatures(result);
				console.log(result, "getSignatures");
			});
		};
		const dataU = async () => {
			await bootstrapFromAPI.getProjectUsers(pspId).then((result) => {
				setPUsers(result);
				console.log(result, "getProjectUsers");
			});
		};
		dataU();
		data();
	}, [pspId]);

	const signClick = () => {
		history.push({
			pathname: `/sign/${pspId}`,
		});
	};

	return (
		<div className={classes.root}>
			<Grid
				container
				spacing={3}>
				<Grid
					item
					xs={12}>
					{" "}
					<br />
					<Typography
						variant="h4"
						gutterBottom
						className={classes.title}>
						Acknowledgement
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}>
					<Card>
						<CardHeader
							className={classes.cardHeaderWhite}
							title="Approved PSP"
						/>
						<CardContent className={classes.projectName}>
							<Typography
								variant="h5"
								className={classes.projectName}>
								{deltekProject && deltekProject.searchName}
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid
					item
					xs={12}>
					<Card>
						<CardContent className={classes.projectName}>
							<Grid
								container
								spacing={10}>
								<Grid
									item
									xs={6}>
									<div className={classes.leftBox}>
										<div>I have read this project’s PSP report and I understand the safety procedures required.</div>
										<br />
										<div>
											I understand that my colleagues depend on me to adhere to these safety standards for their safety
											and my own.
										</div>
										<br />
										<Button
											variant="contained"
											color="secondary"
											onClick={() => window.open(`${API_URI_BASE()}/Report/${pspId}`, "_blank")}>
											VIEW REPORT
										</Button>
									</div>
								</Grid>
								<Grid
									item
									xs={6}>
									<div className={classes.boxContainer}>
										<Card className={classes.rightBox}>
											<Fab
												color="secondary"
												style={{ fontSize: 35 }}>
												{" "}
												&nbsp;
												{/*   <MenuBookIcon style={{ fontSize: 40 }} /> */}
												<CreateIcon />
											</Fab>
											<br />
											<br />
											<Button
												variant="contained"
												color="secondary"
												onClick={signClick}>
												SIGN PSP
											</Button>
										</Card>
									</div>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
				<Grid
					item
					xs={12}>
					<Card>
						<CardHeader
							className={classes.cardHeader}
							title="Project Team Acknowledgements"
						/>
						<CardContent style={{ height: 600, width: "97%" }}>
							{signatures && pUsers && (
								<DataGridPro
									initialState={{
										pagination: {
											paginationModel: { pageSize: 25, page: 0 },
										},
									}}
									getRowId={(row) => {
										return row.id + row.pspRoleId;
									}}
									rows={gridUsers()}
									columns={columns}
									pagination
								/>
							)}
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</div>
	);
};
