const fetch_MyPSP = () => {
	return {
		type: "FETCH_MYPSP",
	};
};

const received_MyPSP = (MyPSP) => {
	return {
		type: "RECEIVED_MYPSP",
		payload: MyPSP,
	};
};

const fetch_MyPSP_Error = () => {
	return {
		type: "FETCH_MYPSP_ERROR",
	};
};

const myPSPActions = {
	fetch_MyPSP,
	received_MyPSP,
	fetch_MyPSP_Error,
};


export default myPSPActions;
