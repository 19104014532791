import axios from "axios";
import allActions from "./redux/actions";
import { dataStore } from "./redux/reduxStore";
import { API_URI_BASE } from "./config";
import { setActiveSig } from "./redux/reducers/ticketReducer";
import { DELTEK_API_URI_BASE } from "./config";
import { setApiConnected } from "./redux/reducers/applicationState";
const axiosConfig = {
	timeout: 100000,
	headers: {
		authorization: `bearer ${localStorage.getItem("token")}`,
	},
};

export const axInstance = axios.create(axiosConfig);
axInstance.interceptors.response.use(undefined, (error) => {
	console.log("-----------AXERROR----------");
	console.log(error);
	//alert(`Issue contacting the api.\n${error}`)
	dataStore.dispatch(setApiConnected(false));
	return;
});

const getVersionInfo = async () => {
	const aprUrl = API_URI_BASE() + "/VersionInformation";
	const response = await axInstance.get(aprUrl);
	if (response.status === 200) {
		return response.data;
	}
};

const approvePSP = async (wbs1, review) => {
	const apiUrl = API_URI_BASE() + "/Projects/approve/" + wbs1;
	/* 	const response = await axInstance.post(apiUrl, review); ///DO NOT WAIT FOR RESPONSE.. this generates the PSP and can take an inordinate amount of time
		if (response.status === 200) {
			return "OK";
		} */
	axInstance.post(apiUrl, review)
	return "OK";

	//return "Error approving PSP";
};
const isProjectUpToDate = async (project) => {
	const apiUrl = API_URI_BASE() + "/Projects/needsSync/" + project.wbs1 + "/" + project.version;
	const response = await axInstance.get(apiUrl);
	if (response.status === 200) {
		return response.data;
	}
	return true;
};

//This is a new function to replace the static list
const fetchDeltekProjectList = async () => {
	const apiUrl = API_URI_BASE() + "/deltek/getProjects";

	let projectList = [];

	const response = await axInstance.get(apiUrl);

	if (response.status === 200) {
		projectList = response.data;
		// dataStore.dispatch(allActions.DeltekProjectListActions.received_ProjectList(projectList));
		return projectList;
	} else {
		return ["Error getting Deltek Project List"];
	}
};

//This is a new function to replace the static list
const fetchDeltekUserList = async () => {
	const apiUrl = DELTEK_API_URI_BASE() + "/employees/getESafetyEmployeeList";

	let userList = [];

	const response = await axInstance.get(apiUrl);

	if (response.status === 200) {
		userList = response.data;
		dataStore.dispatch(allActions.DeltekUserListActions.received_UserList(userList));
		return userList;
	} else {
		return "Error getting Deltek User List";
	}
};

const reOpenPSP = async (wbs1) => {
	const apiUrl = API_URI_BASE() + "/Projects/reopen/" + wbs1;

	const response = await axInstance.get(apiUrl);

	if (response.status === 200) {
		console.log(response.data);
	} else {
		return "Error Reopen PSP";
	}
};


const addFieldUser = async (user, wbs1) => {
	const apiUrl = API_URI_BASE() + "/projects/addProjectUser/" + wbs1;
	const response = await axInstance.post(apiUrl, user);
	if (response.status === 200) {
		return response.data?.project;
	}
	return "Error adding user";

}

const removeFieldUser = async (user, wbs1) => {
	console.log(wbs1)
	const apiUrl = API_URI_BASE() + "/projects/removeProjectUser/" + wbs1;
	const response = await axInstance.post(apiUrl, user);
	if (response.status === 200) {
		return response.data?.project;
	}
	return "Error removing user";
}

export const getStatusText = (sts) => {
	switch (sts) {
		case 1:
			return "In Progress";
		case 2:
			return "Submitted";
		case 3:
			return "Approved";
		case 4:
			return "No Fieldwork";
		default:
			return "Unknown";
	}
};

const fetchAllPSP = async () => {
	// "/Projects" route currently is too big to return data before app crashes, this is the work around - 4/4/23 JS

	const selection = {
		statuses: [1, 2, 3, 4],
		regions: [],
		activityLevels: [],
		businessGroups: [],
		projectUsers: [],
		guidanceDocs: [],
	};

	const response = await axInstance.post(`${API_URI_BASE()}/ProjectQuery/FullQuery`, selection);

	if (response.status === 200) {
		response.data.forEach((a) => {
			a["id"] = a.projectId;
			var sorted = a.projectStatuses.sort(function (status1, status2) {
				return new Date(status2.statusDate) - new Date(status1.statusDate);
			});

			a.currentStatus = sorted.length > 0 ? getStatusText(sorted[0].statusId) : "unk";
		});
		return response.data;
	} else {
		return "Error getting all PSP";
	}
};
const updateCoordinator = async (coordinator) => {
	try {
		const apiUrl = API_URI_BASE() + `/reviewMaps/${coordinator._id}`;

		const response = await axInstance.put(apiUrl, coordinator);
		if (response.status === 200) {
			return "OK";
		} else {
			console.log(response);

			return "Error";
		}
	} catch (error) {
		console.log("Error Message", error.message);
	}
};
const deleteCoordinator = async (coordinator) => {
	try {
		console.log("coordinator", coordinator);
		const apiUrl = API_URI_BASE() + `/reviewMaps/${coordinator}`;

		const response = await axInstance.delete(apiUrl, coordinator);
		if (response.status === 200) {
			return "OK";
		} else {
			console.log(response);

			return "Error";
		}
	} catch (error) {
		console.log("Error Message", error.message);
	}
};

const addCoordinator = async (coordinator) => {
	try {
		const apiUrl = API_URI_BASE() + `/reviewMaps`;

		const response = await axInstance.post(apiUrl, coordinator);
		if (response.status === 200) {
			return "OK";
		} else {
			console.log(response);

			return "Error";
		}
	} catch (error) {
		console.log("Error Message", error.message);
	}
};

const getGuidanceDocuments = async () => {
	const apiUrl = API_URI_BASE() + "/GuidanceDocument";
	console.log("apiUrl", apiUrl);
	const response = await axInstance.get(apiUrl);
	if (response.status === 200) {
		return response.data;
	}
}
//add methods to add, delete and update guidance documents the same way as coordinators



const addGuidanceDocument = async (guidanceDocument) => {
	try {
		const apiUrl = API_URI_BASE() + `/GuidanceDocument`;
		const response = await axInstance.post(apiUrl, guidanceDocument);
		if (response.status === 200) {
			return "OK";
		} else {
			console.log(response);
			return "Error";
		}
	} catch (error) {
		console.log("Error Message", error.message);
	}
};

const deleteGuidanceDocument = async (guidanceDocument) => {
	try {
		const apiUrl = API_URI_BASE() + `/GuidanceDocument/${guidanceDocument}`;
		const response = await axInstance.delete(apiUrl, guidanceDocument);
		if (response.status === 200) {
			return "OK";
		} else {
			console.log(response);
			return "Error";
		}
	} catch (error) {
		console.log("Error Message", error.message);
	}
};

const updateGuidanceDocument = async (guidanceDocument) => {
	try {
		const apiUrl = API_URI_BASE() + `/GuidanceDocument/${guidanceDocument._id}`;
		const response = await axInstance.put(apiUrl, guidanceDocument);
		if (response.status === 200) {
			return "OK";
		} else {
			console.log(response);
			return "Error";
		}
	} catch (error) {
		console.log("Error Message", error.message);
	}
};







const getCoordinators = async () => {
	const apiUrl = API_URI_BASE() + "/reviewMaps";
	console.log("apiUrl", apiUrl);
	const response = await axInstance.get(apiUrl);
	if (response.status === 200) {
		return response.data;
	}
};
const removePSPUser = async (user) => {
	try {
		const apiUrl = API_URI_BASE() + `/projectUsers/delete`;

		const response = await axInstance.post(apiUrl, user);
		if (response.status === 200) {
			return "OK";
		} else {
			console.log(response);

			return "Error";
		}
	} catch (error) {
		console.log("Error Message", error.message);
	}
};

const fetchMyPSP = async (currentUserEmail) => {
	const apiUrl = API_URI_BASE() + "/Projects/email/" + currentUserEmail + "/-1"; // + 3;
	const response = await axInstance.get(apiUrl);

	if (response?.status === 200) {
		var projectList = prepareMyPSP(response.data, currentUserEmail);
		dataStore.dispatch(allActions.MyPSPActions.received_MyPSP(projectList));
	} else {
		return "Error getting My PSP";
	}
};

const prepareMyPSP = (arrMyPSP, currentUserEmail) => {
	const myPSPList = [];
	let myPSP = {};
	arrMyPSP.map((objPSP) => {
		myPSP.projectNumber = objPSP.wbs1;

		//!Changed for new API
		myPSP.projectName = objPSP.projectName; // projectList.find(project => project.wbs1 === objPSP.wbs1) ? projectList.find(project => project.wbs1 === objPSP.wbs1).projectName : "Not found";

		myPSP.lastUpdated = getShortDate(objPSP.lastUpdated);
		myPSP.statusId = objPSP.currentStatus; //!Changed for new API - getPSPStatus(objPSP.projectStatuses);
		myPSP.id = objPSP._id;
		myPSP._id = objPSP._id;

		switch (objPSP.orgCode) {
			case "NC":
				myPSP.org = "Northern California";
				break;
			case "SC":
				myPSP.org = "Southern California";
				break;
			case "NW":
				myPSP.org = "Northwest";
				break;
			case "SE":
				myPSP.org = "Southeast";
				break;
			case "CO":
				myPSP.org = "Corporate";
				break;
			case "DT":
				myPSP.org = "Data Technology";
				break;
			default:
				myPSP.org = "N/A";
				break;
		}

		switch (myPSP.currentStatus) {
			case 1:
				myPSP.edit = true;
				myPSP.report = false;
				myPSP.reOpen = false;
				break;
			case 2:
				myPSP.edit = true;
				myPSP.report = false;
				myPSP.reOpen = false;
				break;
			case 3:
				myPSP.edit = false;
				myPSP.report = true;
				myPSP.reOpen = false;
				break;
			case 4:
				myPSP.edit = false;
				myPSP.report = true;
				myPSP.reOpen = false;
				break;
			default:
				myPSP.edit = true;
				myPSP.report = false;
				myPSP.reOpen = false;
				break;
		}

		myPSP.showAddUser = objPSP.projectUsers
			.filter((user) => user.pspRoleId === 1 || user.pspRoleId === 5)
			.find((user) => user.userId.toLowerCase() === currentUserEmail.toLowerCase())
			? true
			: false;

		myPSP.showAttachments = objPSP.projectUsers
			.filter((user) => user.pspRoleId === 1 || user.pspRoleId === 5 || user.pspRoleId === 6)
			.find((user) => user.userId.toLowerCase() === currentUserEmail.toLowerCase())
			? true
			: false;

		myPSPList.push(myPSP);
		myPSP = {};
		return "";
	});

	return myPSPList;
};

const getShortDate = (inputDate) => {
	var date = new Date(inputDate);
	return (
		(date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
		"/" +
		(date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
		"/" +
		date.getFullYear()
	);
};

const fetchActivityLevels = async () => {
	const apiUrl = API_URI_BASE() + "/ActivityLevels";

	const response = await axInstance.get(apiUrl);

	if (response.status === 200) {
		dataStore.dispatch(allActions.ActivityLevels.received_ActivityLevels(response.data));
	} else {
		return "Error getting Guidance Documents";
	}
};

const getSignatures = async (pspId) => {
	const apiUrl = API_URI_BASE() + "/Signatures/Project/" + pspId;
	const promise = await axInstance.get(apiUrl).then((response) => response.data);
	return promise;
};
const getSignature = async (user, project) => {
	const apiUrl = API_URI_BASE() + "/Signatures/Sigs/" + project + "/" + user;

	axios.get(apiUrl).then((result) => {
		dataStore.dispatch(setActiveSig(result.data));
		return result.data;
	});
};
const getProjectUsers = async (project) => {
	const apiUrl = API_URI_BASE() + "/ProjectUsers/project/" + project;
	const promise = await axInstance.get(apiUrl).then((response) => response.data);
	return promise;
};
const saveSignature = async (sig) => {
	try {
		const apiUrl = API_URI_BASE() + `/Signatures`;

		const response = await axInstance.post(apiUrl, sig);
		//   console.log("value after saved:", response.data);
		if (response.status === 200) {
			//console.log("Saved signature");
			return "OK";
		} else {
			//  console.log(response);

			return "Error";
		}
	} catch (error) {
		console.log("Error Message", error.message);
	}
};

const fetchGuidanceDocuments = async () => {
	const apiUrl = API_URI_BASE() + "/GuidanceDocCategories";

	const response = await axInstance.get(apiUrl);

	if (response.status === 200) {
		dataStore.dispatch(allActions.DocumentActions.received_Documents(response.data));
	} else {
		return "Error getting Guidance Documents";
	}
};

const fetchProject = async (wbs1) => {
	try {
		const apiUrl = API_URI_BASE() + `/Projects/` + wbs1;
		const response = await axInstance.get(apiUrl);
		if (response) {
			if (response.status === 200) {
				dataStore.dispatch(allActions.ProjectActions.received_Project(response.data));
				return response.data;
			} else {
				return "Error finding the project";
			}
		}
	} catch (error) {
		console.log("Error Message", error);
	}
};

const submitProject = async (project) => {
	try {
		const apiUrl = API_URI_BASE() + `/Projects/Submit`;

		const response = await axInstance.post(apiUrl, project);

		if (response.status === 200) {
			dataStore.dispatch(allActions.ProjectActions.saved_Project(response.data));
			return "OK";
		} else {
			dataStore.dispatch(allActions.ProjectActions.saved_Project_Error(response.data));
			return "Error";
		}
	} catch (error) {
		console.log("Error Message", error.message);
	}
};

const saveReview = async (review) => {
	try {
		const apiUrl = API_URI_BASE() + "/ProjectReviews";

		const response = await axInstance.post(apiUrl, review);
		return response;
	} catch (error) {
		console.log("Error Message", error.message);
	}
};
const saveProject = async (project) => {
	if (project.projectStartDate === null || typeof project.projectStartDate === "undefined") {
		project.projectStartDate = "";
	}
	if (project.projectEndDate === null || typeof project.projectEndDate === "undefined") {
		project.projectEndDate = "";
	}

	if (project.projectStartDate.trim() === "") {
		project.projectStartDate = null;
	}
	if (project.projectEndDate.trim() === "") {
		project.projectEndDate = null;
	}

	try {
		const apiUrl = API_URI_BASE() + "/Projects";

		const response = await axInstance.post(apiUrl, project);
		if (response.status === 200) {
			console.log("Project object after saved", response.data);
			dataStore.dispatch(allActions.ProjectActions.saved_Project(response.data));
			return "OK";
		} else {
			console.log(response);
			dataStore.dispatch(allActions.ProjectActions.saved_Project_Error(response.data));
			return "Error";
		}
	} catch (error) {
		console.log("Error Message", error);
	}
};

/* Q U E R Y   P A G E */
const getQueryData = async (selection) => {
	let result = await axInstance.post(`${API_URI_BASE()}/ProjectQuery/FullQuery`, selection);
	return result;
};
//DELETE PROJECT
const deletePSP = async (project) => {
	let result = await axInstance.delete(`${API_URI_BASE()}/Projects/${project}`);
	return result;
};
//Rollback
const rollBackPSP = async (project) => {
	let result = await axInstance.get(`${API_URI_BASE()}/Projects/RollBack/${project}/1`);
	return result;
};

const getListOfFilesForSinglePSP = async (wbs1) => {
	const result = await axInstance.get(`${API_URI_BASE()}/documents/list/${wbs1}`);
	return result;
};

const postFileForSinglePSP = async (wbs1, fileData) => {
	const result = await axInstance.post(`${API_URI_BASE()}/documents/${wbs1}`, fileData);
	return result;
};

const deleteFileForSinglePSP = async (wbs1, fileName) => {
	const result = await axInstance.delete(`${API_URI_BASE()}/documents/${wbs1}/${fileName}`);
	return result;
};

const downloadFileAttachedToPSP = async (wbs1, fileName) => {
	const result = await axInstance.get(`${API_URI_BASE()}/documents/download/${wbs1}/${fileName}`, {
		responseType: "blob",
	});
	return result;
};

let bootstrapFromAPI = {
	approvePSP,
	rollBackPSP,
	deletePSP,
	getQueryData,
	submitProject,
	fetchProject,
	saveProject,
	fetchAllPSP,
	fetchActivityLevels,
	fetchGuidanceDocuments,
	fetchDeltekProjectList,
	fetchDeltekUserList,
	saveReview,
	fetchMyPSP,
	saveSignature,
	getSignature,
	getSignatures,
	getProjectUsers,
	isProjectUpToDate,
	getVersionInfo,
	reOpenPSP,
	removePSPUser,
	getListOfFilesForSinglePSP,
	postFileForSinglePSP,
	deleteFileForSinglePSP,
	downloadFileAttachedToPSP,
	getCoordinators,
	addCoordinator,
	deleteCoordinator,
	updateCoordinator,
	addGuidanceDocument,
	updateGuidanceDocument,
	deleteGuidanceDocument,
	getGuidanceDocuments,
	addFieldUser,
	removeFieldUser
};


export default bootstrapFromAPI
