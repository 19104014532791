import { combineReducers } from "redux";
import ProjectReducer from "./projectReducer";
import DocumentReducer from "./documentReducer";
import ActivityLevelReducer from "./activityLevelReducer";
import DeltekProjectReducer from "./deltekProjectReducer";
import AuthenReducer from "./authenReducer";
import TicketReducer from "./ticketReducer";
import MyPSPReducer from "./myPSPReducer";
import DeltekProjectListReducer from "./projectListReducer";
import DeltekUserListReducer from "./deltekUserReducer";
import ApplicationStateReducer from "./applicationState";


const rootReducers = combineReducers({
	DeltekProjectReducer,
	ProjectReducer,
	ActivityLevelReducer,
	DocumentReducer,
	AuthenReducer,
	TicketReducer,
	MyPSPReducer,
	DeltekUserListReducer,
	DeltekProjectListReducer,
	ApplicationStateReducer,
});

export default rootReducers;
