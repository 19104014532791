import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { TrainingStyles } from "../../Styles";


const Training = () => {
	const classes = TrainingStyles();

	return (
		<>
			<div className={classes.root}>
				<br />
				<br />
				<Grid
					container
					spacing={3}>
					<Grid
						item
						xs={1}></Grid>
					<Grid
						item
						xs={10}>
						<Grid
							container
							spacing={3}>
							<Grid
								item
								xs={6}
								className={classes.leftAlign}>
								<img
									src={require("../../images/Home_Icon-Training_Circle.png")}
									alt="Training"
								/>
								&ensp;
								<span className={classes.training}>Training</span>
							</Grid>
						</Grid>
						<div className={classes.sectionHeader}>
							Annual Training
							<hr />
						</div>
						<div className={classes.sectionSubHeader}></div>
						<div>
							<Card>
								<CardContent>
									<br />
									<Grid
										container
										spacing={3}>
										<Grid
											item
											xs={7}
											className={classes.clickSafetyText}>
											<span className={classes.clickSafetyText}>
												ClickSafety training courses are mandatory for all ESA employee-owners with training groups
												covering office training, basic field training, and construction. New hires must complete
												initial training depending on role, and all staff will participate in annual refresh training
												which includes a subset of these topics to increase our safety training and performance across
												the firm. Training will be assigned to your account; to find your login credentials please visit
												the <a href="http://inside.esa.local/Safety/Pages/ClickSafety.aspx">Intranet Safety Page</a>. If
												you experience any issues or have a question about ClickSafety, please reach out to the{" "}
												<a href="mailto:safety@esassoc.com">ESA Safety Team</a>.
											</span>
										</Grid>
										<Grid
											item
											xs={5}>
											<a href="https://www.clicksafety.com/customer/account/login">
												<img
													src={require("../../images/Click_Safety.png")}
													alt="Click Safety"
												/>
											</a>
										</Grid>
										<br />
									</Grid>
								</CardContent>
							</Card>
						</div>
						<br />
						<br />
						<div className={classes.sectionHeader}>
							Other Training Curriculum
							<hr />
						</div>
						<br />

						<div className={classes.curriculum}>ESA INTERNAL TRAINING</div>
						<div className={classes.course}>
							<a href="https://vimeo.com/696209833/c282abeb32">Field Cohort Training</a>
						</div>
						<div className={classes.courseDescription}>
							Required for field staff, assigned by <a href="mailto:safety@esassoc.com">ESA Safety Team</a>
						</div>

						<div className={classes.curriculum}>FIRST AID TRAINING</div>
						<div className={classes.course}>
							<a href="https://www.clicksafety.com/customer/account/login">CPR/First Aid Training</a>
						</div>
						<div className={classes.courseDescription}>
							Required for field staff, assigned by <a href="mailto:safety@esassoc.com">ESA Safety Team</a>
						</div>
						<div className={classes.course}>Back County Life Saving</div>
						<div className={classes.courseDescription}>
							Approved and assigned by Project Manager <br />
							<a href="https://sierrarescue.com/course-info/wilderness-first-aid-cpr-8-hour/">Norcal</a>
						</div>

						<div className={classes.curriculum}>CULTURAL RESOURCES</div>
						<div className={classes.course}>Confined Space Recognition</div>
						<div className={classes.courseDescription}>
							Approved by Project Manager, assigned by <a href="mailto:safety@esassoc.com">ESA Safety Team</a>
						</div>
						<div className={classes.course}>Trench Safety</div>
						<div className={classes.courseDescription}>
							Approved by Project Manager, assigned by <a href="mailto:safety@esassoc.com">ESA Safety Team</a>
						</div>

						<div className={classes.curriculum}>HAZARDOUS WASTE</div>
						<div className={classes.course}>
							<a href="https://www.clicksafety.com/customer/account/login">Hazwoper training</a>
						</div>
						<div className={classes.courseDescription}>
							Approved by Manager, assigned by <a href="mailto:safety@esassoc.com">ESA Safety Team</a>
						</div>

						<div className={classes.curriculum}>WATER</div>
						<div className={classes.course}>
							<a href="https://www.boaterexam.com/usa">USCG Boat Pilot Training</a>
						</div>
						<div className={classes.courseDescription}>Approved and assigned by Project Manager</div>
						<div className={classes.course}>Swift Water Rescue</div>
						<div className={classes.courseDescription}>
							Approved and assigned by Project Manager <br />
							<a href="https://sierrarescue.com/course-info/swiftwater-rescue-technician-unit-1/">Norcal</a>
						</div>

						<div className={classes.curriculum}>WILDLIFE</div>
						<div className={classes.course}>Venomous Snake Handling</div>
						<div className={classes.courseDescription}>
							Approved and assigned by Project Manager <br />
							<a href="https://www.savethebuzztails.org/inforequest">Southeast</a>
						</div>
					</Grid>
					<Grid
						item
						xs={1}></Grid>
				</Grid>
			</div>
		</>
	);
};

export default Training;
