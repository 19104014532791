import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { dataStore } from "./redux/reduxStore";

import IdentityProvider from "./components/Authentication/IdentityProvider";
import { LicenseInfo } from "@mui/x-license-pro";
import { createTheme, MuiThemeProvider } from "@material-ui/core";

LicenseInfo.setLicenseKey(
	"514a76d5aa44bb0035380966f28502feTz0xMDE0NTYsRT0xNzYyNjM0MDI4MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=",
);

const theme = createTheme({
	palette: {
		secondary: {
			main: "#007C90",
		},
	},
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
	<Provider store={dataStore}>

		<IdentityProvider>
			<MuiThemeProvider theme={theme}>
				<App />
			</MuiThemeProvider>
		</IdentityProvider>

	</Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
