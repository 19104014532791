import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../redux/actions";
import bootstrapFromAPI from "../../bootstrapData";
import { Fab, StepConnector, Button, StepButton } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Submit from "../PSP/Submit/Submit.Component";
import ProjectInfoBar from "../PSP/Common/ProjectInfo.Component";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles({
	root: {
		background: "linear-gradient(45deg, #65BBC5 30%, #064A67 90%)",
		border: 0,
		boxShadow: "0 3px 5px 2px rgba(101, 187, 197, .3)",
		color: "white",
		padding: "10 30px",
	},
	actionBtns: {
		width: "100%",
		display: "flex",
		marginTop: "1rem",
		marginBottom: "1rem",
		justifyContent: "flex-end",
	},
});

function Alert(props) {
	return (

		<MuiAlert
			elevation={6}
			variant="filled"
			{...props}
		/>
	);
}

const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 22,
	},
	active: {
		"& $line": {
			backgroundImage: "linear-gradient(45deg, #65BBC5 30%, #064A67 90%)",
		},
	},
	completed: {
		"& $line": {
			backgroundImage: "linear-gradient(45deg, #65BBC5 30%, #064A67 90%)",
		},
	},
	line: {
		zIndex: 1000,
		height: 3,
		border: 0,
		backgroundColor: "#eaeaf0",
		borderRadius: 1,
		width: "70%",
	},
})(StepConnector);
export default function ProgressTracker({ steps }) {
	const currentUser = useSelector((state) => state.AuthenReducer.userObj);
	const currentPsp = useSelector((state) => state.ProjectReducer);
	const isReadOnly = useSelector((state) => state.ProjectReducer.isReadOnly);
	const [projectSelected, setProjectSelected] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [open, setOpen] = useState(false);
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		if (currentPsp.projectInfo.wbs1.length > 0) {
			setProjectSelected(true);
		}

		if (currentPsp.isReadyToSubmit && !isReadOnly) {
			const handelSubmit = async () => {
				if ((await bootstrapFromAPI.submitProject(currentPsp.projectInfo)) === "OK") {
					await bootstrapFromAPI.fetchMyPSP(currentUser.user ? currentUser.user.userPrincipalName : "", -1);
					setActiveStep((prevActiveStep) => prevActiveStep + 1);
				}
			};

			handelSubmit();
		}
	}, [currentPsp, currentUser, isReadOnly]);

	const handleNext = async () => {
		if (isReadOnly) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		} else {
			if (activeStep === 0) {
				await stepOneSave();
				history.push(`/step1/${currentPsp.projectInfo.wbs1}`);
			} else {
				if ((await bootstrapFromAPI.saveProject(currentPsp.projectInfo)) === "OK") {
					setOpen(true);
					setActiveStep((prevActiveStep) => prevActiveStep + 1);
				}
			}
		}
	};

	const handleBack = async () => {
		if (isReadOnly) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		} else {
			if ((await bootstrapFromAPI.saveProject(currentPsp.projectInfo)) === "OK") {
				console.log("activeStep", activeStep);
				setOpen(true);

				setActiveStep((prevActiveStep) => prevActiveStep - 1);
			}
		}
	};

	const handleStep = async (step) => {
		if (isReadOnly) {
			setActiveStep(step);
		} else {
			if ((await bootstrapFromAPI.saveProject(currentPsp.projectInfo)) === "OK") {
				console.log("activeStep", activeStep);
				setOpen(true);
				setActiveStep(step);
			}
		}
	};

	const stepOneSave = async () => {
		//add user as creator (role 5)
		currentPsp.projectInfo.projectUsers
			.filter((user) => user.pspRoleId === 5)
			.find((user) => user.userId.toLowerCase() === currentUser.user.mail.toLowerCase())
			? console.log("User Already Exist")
			: currentPsp.projectInfo.projectUsers.push({
				projectId: currentPsp.projectInfo.projectId,
				userId: currentUser.user.mail,
				pspRoleId: 5,
				fullName: currentUser.user.displayName,
			});

		// If PSP has no activity levels skip to Step 6
		if (currentPsp.projectInfo.projectActivityLevels.find((al) => al.activityLevelId === 10)) {
			currentPsp.projectInfo.projectActivityLevels = currentPsp.projectInfo.projectActivityLevels.filter(
				(al) => al.activityLevelId === 10,
			);
			dispatch(allActions.ProjectActions.saved_Project(currentPsp.projectInfo));
			setOpen(true);
			setActiveStep(5);
		} else {
			if ((await bootstrapFromAPI.saveProject(currentPsp.projectInfo)) === "OK") {
				setOpen(true);
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		}

		await bootstrapFromAPI.fetchDeltekProjectList();
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};

	return (
		<>
			<Stepper
				connector={<ColorlibConnector align="middle" />}
				alternativeLabel
				nonLinear
				activeStep={activeStep}>
				{steps.map((item, index) => (
					<Step key={item.title}>
						<StepButton
							onClick={() => handleStep(index)}
							disabled={!projectSelected}>
							<StepLabel
								StepIconComponent={() => (
									<Fab
										className={classes.root}
										style={{ opacity: projectSelected ? 1 : 0.2 }}>
										{item.icon}
									</Fab>
								)}>
								{" "}
								{item.title}
							</StepLabel>
						</StepButton>
					</Step>
				))}
			</Stepper>
			<div>
				<ProjectInfoBar
					projectInfo={{
						projectNumber: currentPsp.projectInfo.wbs1,
						projectName: currentPsp.projectInfo.projectName,
					}}
				/>
				{activeStep === steps.length ? (
					<Submit />
				) : (
					<div>
						<div className={classes.actionBtns}>
							<Button
								disabled={activeStep === 0}
								onClick={handleBack}
								className={classes.backButton}>
								Back
							</Button>
							<Button
								disabled={activeStep === steps.length - 1 || !projectSelected}
								variant="contained"
								color="secondary"
								onClick={handleNext}>
								Next
							</Button>
						</div>
						{steps[activeStep].component}
						<div className={classes.actionBtns}>
							<Button
								disabled={activeStep === 0}
								onClick={handleBack}
								className={classes.backButton}>
								Back
							</Button>
							<Button
								disabled={activeStep === steps.length - 1 || !projectSelected}
								variant="contained"
								color="secondary"
								onClick={handleNext}>
								Next
							</Button>
						</div>
					</div>
				)}
			</div>
			<Snackbar
				open={open}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				autoHideDuration={1000}
				onClose={handleCloseSnackbar}>
				<Alert
					onClose={handleCloseSnackbar}
					severity="success">
					Your progess has been updated!
				</Alert>
			</Snackbar>
		</>
	);
}
