import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import allActions from "../../redux/actions";

const gdNumber = (obj) => {
	if (obj.indexOf(" ") > -1) {
		let spl = obj.split(" ");
		return `${spl[0]} ${spl[1]}`;
	}
	return obj;
};

const GuidanceDocument = ({ objVal }) => {
	const dispatch = useDispatch();
	const [, refresh] = useState();
	const objProject = useSelector((state) => state.ProjectReducer.projectInfo);

	//Handle read only
	const isReadOnly = useSelector((state) => state.ProjectReducer.isReadOnly);

	function isChecked() {
		return objProject.projectGuidanceDocuments.find((gd) => gd.gdId === objVal.gdId && gd.selected) !== undefined;
	}

	const getLabel = () => {
		if (objVal.tooltip !== null) {
			return (
				<>
					<Typography
						variant="body2"
						data-tip
						data-for={"tt_" + objVal.gdId}>
						{" "}
						{gdNumber(objVal.filename)} {objVal.gdName.replace(".pdf", "")}
					</Typography>{" "}
					<ReactTooltip
						id={"tt_" + objVal.gdId}
						placement={"top"}>
						<span>{objVal.tooltip}</span>
					</ReactTooltip>
				</>
			);
		} else {
			return <Typography variant="body2"> {objVal.fileName}</Typography>;
		}
	};

	const handleChange = (event) => {
		event.target.checked
			? (objProject.projectGuidanceDocuments.find((gd) => gd.gdId === objVal.gdId).selected = true)
			: (objProject.projectGuidanceDocuments.find((gd) => gd.gdId === objVal.gdId).selected = false);

		dispatch(allActions.ProjectActions.saved_Project(objProject));

		refresh({});
	};

	return (
		<>
			<FormControlLabel
				control={
					<Checkbox
						onClick={handleChange}
						name={`gdId` + objVal.gdId}
						color="secondary"
						checked={isChecked()}
						disabled={isReadOnly}
					/>
				}
				label={getLabel()}
			/>
			<hr />
		</>
	);
};

export default GuidanceDocument;
