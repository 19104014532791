import axios from "axios";
import { axInstance } from "../bootstrapData";
import { API_URI_BASE } from "../config";
import { GOOGLE_API_KEY } from "../config";

export const getQueryOptions = async () => {
	const apiUrl = API_URI_BASE() + "/ProjectQuery/Options";
	var response = await axInstance.get(apiUrl);
	return response.data;
};

//Just gives the text for the role
export function getRoleFromId(roleId) {
	const roles = ["Project Manager", "Field Leader", "Field Personnel", "Safety Officer", "PSP Creator"];

	return roleId - 1 > roles.length - 1 ? "No Role" : roles[roleId - 1];
}

//Just figures out what state the project is in - 1-inprogress, 2-review, 3- finalized
export function projectState(project) {
	//sort status array
}
export const Reviewers = async () => {
	const apiUrl = API_URI_BASE() + "/Reviewers";
	var resp = await axInstance.get(apiUrl);
	return resp.data;
};
export const isAdmin = async (user) => {
	if (user === undefined) return false;
	const apiUrl = API_URI_BASE() + "/Reviewers/" + user;
	var response = await axInstance.get(apiUrl);

	return response.data.isAdmin;
};

export const getDirections = async (origin, destination) => {
	var dirURI =
		"https://maps.googleapis.com/maps/api/directions/json?origin=" +
		origin +
		"&destination=" +
		destination +
		"&key=" +
		GOOGLE_API_KEY;
	var resp = await axios.get(dirURI);
	if (resp) {
		return resp.data;
	}
	return null;
};

export const validBrowser = () => {
	var brws = getBrowser();
	if (["Google Chrome or Chromium", "Microsoft Edge"].indexOf(brws) > -1) {
		return true;
	}
	return false;
};
export const getBrowser = () => {
	var sBrowser,
		sUsrAg = navigator.userAgent;

	// The order matters here, and this may report false positives for unlisted browsers.

	if (sUsrAg.indexOf("Firefox") > -1) {
		sBrowser = "Mozilla Firefox";
		// "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
	} else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
		sBrowser = "Samsung Internet";
		// "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
	} else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
		sBrowser = "Opera";
		// "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
	} else if (sUsrAg.indexOf("Trident") > -1) {
		sBrowser = "Microsoft Internet Explorer";
		// "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
	} else if (sUsrAg.indexOf("Edge") > -1) {
		sBrowser = "Microsoft Edge";
		// "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
	} else if (sUsrAg.indexOf("Chrome") > -1) {
		sBrowser = "Google Chrome or Chromium";
		// "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
	} else if (sUsrAg.indexOf("Safari") > -1) {
		sBrowser = "Apple Safari";
		// "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
	} else {
		sBrowser = "unknown";
	}
	return sBrowser;
};

export const getVersionInformation = () => {};
