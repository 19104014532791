/* 
        Project             : eSafety 2.0
        Client              : Internal
        Author Company      : ESA
        Author              : Peter Ross
        Contact             : pross@esassoc.com

*/
import {
	Button,
	Checkbox,
	Dialog,
	Fab,
	FormControlLabel,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import allActions from "../../../../redux/actions";
import CommentIcon from "@material-ui/icons/Comment";
import ReplyIcon from "@material-ui/icons/Reply";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ticketStyles } from "../../../../Styles";
import Close from "@material-ui/icons/Close";
import Save from "@material-ui/icons/SaveAlt";

//PROSS f
//Formats native sql server dates...
const formatDate = (dateString) => {
	var strDate = "";
	var dtObj = new Date(dateString);
	strDate =
		dtObj.getMonth() +
		1 +
		"/" +
		dtObj.getDate() +
		"/" +
		dtObj.getFullYear() +
		" " +
		(dtObj.getHours() > 12 ? dtObj.getHours() - 12 : dtObj.getHours()) +
		":" +
		dtObj.getMinutes() +
		" " +
		(dtObj.getHours() > 12 ? "PM" : "AM");
	return strDate;
};

export default function ESAComment({ ticket, cb_createChild }) {
	const [activeDiag, setActiveDiag] = useState(false);
	const userObj = useSelector((state) => state.AuthenReducer.userObj);
	const defaultTicket = {
		statusid: 1,
		projectId: 206,
		parentTicket: null,
		userId: userObj.user.mail,
		userName: userObj.user.displayName,
		creationDate: "2020-11-21T13:58:52.137",
		closeDate: null,
		changeMade: false,
		comment: "No comment",
		ticketScope: "__control__1",
		parentTicketNavigation: null,
		project: null,
		status: { statusCaption: "pending" },
	};
	const disp = useDispatch();
	const [value, setValue] = useState();
	const [, setDiag] = useState(false);

	const project = useSelector((state) => state.ProjectReducer.projectInfo);
	const objTicket = ticket ? ticket : defaultTicket;
	const [ele, setEle] = useState(2);
	const [, setState] = useState({});
	const classes = ticketStyles();
	const commentChange = (a) => {
		setValue(a.target.value);
	};
	function select(sel) {
		setEle(sel ? 10 : 2);
	}
	function getStatus(val) {
		if (val === 2) return "Pending";
		if (val === 3) return "Closed";
		return "Open";
	}
	function handleReply() {
		setDiag(true);
		setActiveDiag(true);
		if (cb_createChild) cb_createChild(ticket);
	}

	function saveComment() {
		let newTicket = {
			...defaultTicket,
			comment: value,
			userId: userObj.user.mail,
			userName: userObj.user.displayName,
			ticketScope: objTicket.ticketScope,
			parentTicketId: objTicket.ticketId,
			projectId: project.projectId,
		};
		setValue("");

		project.tickets.push(newTicket);
		let newProject = { ...project };

		disp(allActions.ProjectActions.saved_Project(newProject));
		setState({});
	}

	function handleChangeMade(x, val) {
		objTicket.changeMade = val;
		objTicket.closeDate = val ? new Date().toLocaleDateString() : undefined;
		objTicket.statusid = val ? 3 : 1;
		setState({});
	}

	return (
		<>
			<Dialog open={activeDiag}>
				<Paper
					style={{ padding: 10 }}
					elevation={10}
					width={600}>
					<TextField
						id="new-ticket-comment-field"
						label="New Reply"
						multiline
						fullWidth={true}
						maxRows={8}
						value={value}
						onChange={commentChange}
						variant="outlined"
					/>
					<Button onClick={() => setActiveDiag(false)}>
						<Close />
						Close
					</Button>{" "}
					<Button
						onClick={() => {
							saveComment();
							setActiveDiag(false);
						}}>
						<Save />
						Save
					</Button>
				</Paper>
			</Dialog>
			<Paper
				style={{ cursor: "pointer", borderBottom: 5 }}
				elevation={parseInt(ele)}
				onMouseOver={() => select(true)}
				onMouseOut={() => select(false)}>
				<Grid
					container
					className={classes.ticketCommentText}>
					<Grid
						item
						xs={12}>
						<table
							cellSpacing="0"
							cellPadding="10"
							className={classes.ticketTitleBar}>
							<tbody>
								<tr>
									<td
										cellSpacing={5}
										width={10}>
										<Fab
											size="small"
											className={classes.ticketIcon}>
											<CommentIcon />
										</Fab>{" "}
									</td>
									<td
										valign="middle"
										align="left">
										<table
											cellPadding="0"
											cellSpacing="0">
											<tbody>
												<tr>
													<td>
														<span className={classes.ticketUser}>{objTicket.userName}</span>
													</td>
												</tr>
												<tr>
													<td>
														<span className={classes.ticketDate}>{formatDate(objTicket.creationDate)}</span>
													</td>
												</tr>
											</tbody>
										</table>
									</td>
									<td
										align="center"
										className={classes["ticketStatus" + getStatus(objTicket.statusid).toLowerCase()]}>
										<div className={classes.ticketStatusText}>{getStatus(objTicket.statusid).toUpperCase()}</div>
									</td>
								</tr>
							</tbody>
						</table>
					</Grid>
					<Grid
						item
						xs={12}
						className={classes.ticketCommentText}>
						<Typography
							variant="body2"
							style={{ padding: 10 }}>
							{objTicket.comment}
						</Typography>
					</Grid>
					<br />
					<br />
					<Grid
						item
						xs={12}
						className={classes.ticketCommentText}>
						{project.tickets
							.filter((a) => a.parentTicketId === objTicket.ticketId)
							.map((child) => (
								<Grid
									container
									className={classes.childTicket}>
									<Grid
										item
										xs={12}>
										<div className={classes.childTicketUser}>{child.userName}</div>
									</Grid>
									<Grid
										item
										xs={12}>
										<div className={classes.childTicketDate}>{formatDate(objTicket.creationDate)}</div>
									</Grid>
									<Grid
										item
										xs={12}>
										<div className={classes.childTicketComment}>{child.comment}</div>{" "}
									</Grid>
								</Grid>
							))}
						<br />
					</Grid>

					<Grid
						item
						xs={12}>
						<Grid container>
							<Grid
								item
								xs={6}>
								<FormControlLabel
									label="Changes Made"
									title="Changes Made"
									control={
										<Checkbox
											onChange={handleChangeMade}
											color="secondary"
											checked={ticket.changeMade}
											id="changes-made"
										/>
									}
								/>
							</Grid>
							<Grid
								item
								xs={6}>
								<div align="right">
									{objTicket.closeDate !== undefined && (
										<>
											<IconButton
												id="reply-button"
												onClick={handleReply}>
												<ReplyIcon className={classes.ticketControl} />
											</IconButton>
										</>
									)}
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		</>
	);
}
