import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import bootstrapFromAPI from "../../../bootstrapData";
import allActions from "../../../redux/actions";
import { StepOneStyles } from "../../../Styles";
import ActivityLevel from "../../Control/ActivityLevel.Component";
import TicketScope from "../../Ticket/ticketScope";
import VersionCheck from "../../Control/VersionCheck";
import ApprovedDialog from "../../Generic/ApprovedDialog";

const StepOne = () => {
	const currentUser = useSelector((state) => state.AuthenReducer.userObj);
	const activityLevels = useSelector((state) => state.ActivityLevelReducer.activityLevels);
	const currentPsp = useSelector((state) => state.ProjectReducer);
	const isReadOnly = useSelector((state) => state.ProjectReducer.isReadOnly);
	const [deltekProjects, setDeltekProjects] = useState([]);
	const { wsb1 } = useParams();
	const classes = StepOneStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		const fetchData = async () => {
			if (wsb1) {
				const foundProject = await bootstrapFromAPI.fetchProject(wsb1);
				setDeltekProjects(foundProject);
				dispatch(allActions.ProjectActions.set_Project_ReadOnly(foundProject, currentUser));
				dispatch(allActions.DeltekProjectActions.saved_Deltek_Project(foundProject)); // Not sure if this is necessary - 3/28 JS
			} else {
				const resposne = await bootstrapFromAPI.fetchDeltekProjectList();
				setDeltekProjects(resposne);
				dispatch(allActions.ProjectActions.clear_Project());
				dispatch(allActions.DeltekProjectActions.clear_Deltek_Project());
			}
		};

		fetchData();
	}, [wsb1, dispatch, currentUser]);


	const handleSelectedProject = async (event, value) => {
		if (value !== undefined && value !== null) {
			const result = await bootstrapFromAPI.fetchProject(value.wbs1);

			let objProject = currentPsp.projectInfo;

			objProject = { ...result };

			objProject.orgCode = value.organization.substring(0, 2);
			objProject.projectName = value.projectName;
			objProject.businessGroup = value.organizationName;
			objProject.projectStartDate = value.startDate;
			objProject.projectEndDate = value.endDate;
			//add PM role (role 1)
			objProject.projectUsers
				.filter((user) => user.pspRoleId === 1)
				.find((user) => user.userId.toLowerCase() === value.mail.toLowerCase())
				? console.log("User Already Exist")
				: console.log(value);
			objProject.projectUsers.push({
				projectId: objProject.projectId,
				userId: value.email,
				pspRoleId: 1,
				fullName: value.projectManager,
			});

			dispatch(allActions.ProjectActions.set_Project_ReadOnly(objProject, currentUser));
			dispatch(allActions.ProjectActions.saved_Project(objProject));
		}
	};

	const isDependant = (item) => {
		var foundItem = activityLevels.find((a) => a.requires === item.activityLevelId);
		return foundItem ? true : false;
	};

	return currentPsp.projectInfo.currentStatus === 3 ? (
		<ApprovedDialog />
	) : (
		<>
			<VersionCheck />
			<div>
				<Grid
					container
					spacing={3}>
					<Grid
						item
						xs={12}>
						<Card>
							<CardHeader
								className={classes.cardHeader}
								title="Step 1: Initial Determination"
							/>
							<CardContent>
								<Grid
									container
									spacing={3}>
									<Grid
										item
										xs={5}>
										<Autocomplete
											getOptionDisabled={(option) => option.hasPSP}
											id="deltekProjectName"
											options={deltekProjects}
											getOptionLabel={(option) => option.searchName}
											onChange={handleSelectedProject}
											disabled={currentPsp.projectInfo?.projectName}
											renderInput={(params) => (
												<TextField
													{...params}
													label={currentPsp.projectInfo?.projectName || "Select a Project Name"}
													disabled={isReadOnly}
													required
												/>
											)}
										/>
									</Grid>
									<Grid
										item
										xs={3}>
										<TextField
											disabled={true}
											value={currentPsp.projectInfo?.businessGroup}
											id="businessGroup"
											fullWidth={true}
											label="Business Group"
										/>
									</Grid>
									<Grid
										item
										xs={2}>
										<TextField
											disabled={true}
											value={currentPsp.projectInfo?.projectStartDate || ""}
											id="StartDate"
											fullWidth={true}
											label="Start Date"
										/>
									</Grid>
									<Grid
										item
										xs={2}>
										<TextField
											disabled={true}
											value={currentPsp.projectInfo?.projectEndDate || ""}
											id="EndDate"
											fullWidth={true}
											label="End Date"
										/>
									</Grid>
								</Grid>
								<br />
								<br />
								<TicketScope
									reviewMode={true}
									scope="__step_1">
									{currentPsp.projectInfo.wbs1.length > 0 ? (
										<Grid
											container
											spacing={0}>
											<Grid
												item
												xs={12}>
												<Typography variant="h6">Determine Level of Project Activity</Typography>
												<br />
												<Typography variant="body1">Check all that apply</Typography>
											</Grid>
											{activityLevels.map((activityLevel) => (
												<Grid
													item
													xs={12}
													key={activityLevel.activityLevelId}>
													<ActivityLevel
														disabled={isDependant(activityLevel)}
														classes={classes}
														objVal={activityLevel}
													/>
													<br />
												</Grid>
											))}
										</Grid>
									) : (
										<></>
									)}
								</TicketScope>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default StepOne;
