export const tickTemplate = {
	statusid: 1,

	parentTicket: null,
	userId: "unk@esassoc.com",
	userName: "Unknown User",
	creationDate: new Date().toJSON(),

	changeMade: false,
	comment: "",
	ticketScope: "",

	project: null,
	statusId: 1,
	/*status : {
        "statusId": 1,
        "statusCaption": "Open",
     
    }*/
};
