import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { RotateSpinner } from "react-spinners-kit";
import { StepTwoStyles } from "../../../Styles";
import ProjectActivity from "../../Control/Project.Activity.Component";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { clearScopes } from "../../../redux/reducers/ticketReducer";
import VersionCheck from "../../Control/VersionCheck";

const StepTwo_One = () => {
	const [loaded, setLoaded] = useState(true);
	const currentPsp = useSelector((state) => state.ProjectReducer);
	const guidanceDocCategories = useSelector((state) => state.DocumentReducer.documentSet);
	const classes = StepTwoStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearScopes());
		setLoaded(false);
	}, [dispatch]);

	return loaded ? (
		<div style={{ padding: "2rem" }}>
			<RotateSpinner
				size={45}
				color="#686769"
			/>
		</div>
	) : (
		<>
			<VersionCheck />
			<div className={classes.root}>
				<Grid
					container
					spacing={3}>
					<Grid
						item
						xs={12}></Grid>
					<Grid
						item
						xs={12}>
						<Card>
							<CardHeader
								className={classes.cardHeader}
								title="Step 2-1: Project Activities"
							/>
							<br />
							<CardContent>
								{guidanceDocCategories.map((category) => {
									if (
										category.gdcategory === "Construction Site Safety" &&
										currentPsp.projectInfo.projectActivityLevels.find((activity) => activity.activityLevelId === 3)
									) {
										return category.guidanceDocuments.length > 0 ? (
											<ProjectActivity
												Category={category}
												key={category.gdcategoryId}
											/>
										) : (
											""
										);
									} else {
										if (category.gdcategory !== "Construction Site Safety") {
											return category.guidanceDocuments.length > 0 ? (
												<ProjectActivity
													Category={category}
													key={category.gdcategoryId}
												/>
											) : (
												""
											);
										}
									}
									return "";
								})}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default StepTwo_One;
