/* 
        Project             : eSafety 2.0
        Client              : Internal
        Author Company      : ESA
        Author              : Peter Ross
        Contact             : pross@esassoc.com

*/
import { createSlice } from "@reduxjs/toolkit";
export const Ticket = createSlice({
	name: "ticket",
	initialState: {
		currentTicket: {},
		currentScope: "",
		currentScopes: [],
		userIsApprover: false,
		//  reviewMode:false,
		readOnlyMode: false,
		activeReview: undefined,
		activeSig: undefined,
	},
	reducers: {
		setActiveSig: (state, action) => {
			state.activeSig = action.payload;
		},
		addScope: (state, action) => {
			state.currentScopes.push(action.payload);
		},
		clearScopes: (state, action) => {
			state.currentScopes = [];
		},
		setCurrentScopes: (state, action) => {
			state.currentScopes = action.payload;
		},
		setActiveReview: (state, action) => {
			state.activeReview = action.payload;
		},
		setProject: (state, action) => {
			state.project = action.payload;
		},
		setROMode: (state, action) => {
			state.readOnlyMode = action.payload;
		},
		setCurrentTicket: (state, action) => {
			state.currentTicket = action.payload;
		},
		setCurrentScope: (state, action) => {
			state.currentScope = action.payload;
		},
		setApprover: (state, action) => {
			state.Approver = action.payload;
		},
		setReviewMode: (state, action) => {
			state.reviewMode = action.payload;
		},
	},
});
export const project = (state) => state.ticket.project;
export const currentTicket = (state) => state.ticket.currentTicket;
export const {
	setReviewMode,
	setROMode,
	addScope,
	clearScopes,
	currentScopes,
	setCurrentTicket,
	setCurrentScope,
	setApprover,
	setProject,
	setActiveReview,
	setCurrentScopes,
	setActiveSig,
} = Ticket.actions;
export default Ticket.reducer;
