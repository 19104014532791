/* 
        Project             : eSafety 2.0
        Client              : Internal
        Author Company      : ESA
        Author              : Peter Ross
        Contact             : pross@esassoc.com
*/

import { createSlice } from "@reduxjs/toolkit";

export const ApplicationState = createSlice({
    name: "applicationState",
    initialState: {
        status: "idle", // Example statuses: 'idle', 'loading', 'succeeded', 'failed'
        apiConnected: false, // To track if the application is connected to the API
    },
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        setApiConnected: (state, action) => {
            state.apiConnected = action.payload;
        },
        resetState: (state) => {
            state.status = "idle";
            state.apiConnected = false;
        },
    },
});

// Selectors for accessing state values
export const status = (state) => state.applicationState.status;
export const apiConnected = (state) => state.applicationState.apiConnected;

// Export actions for dispatching
export const { setStatus, setApiConnected, resetState } = ApplicationState.actions;

// Export the reducer for use in store configuration
export default ApplicationState.reducer;
