import { deltekProject } from "../../models/deltekProject";

const initialState = {
	deltekProject: deltekProject,
	isProcessing: false,
	isError: false,
};

const DeltekProjectReducer = (state = initialState, deltekAction) => {
	switch (deltekAction.type) {
		case "FETCH_DELTEK_PROJECT": {
			return {
				deltekProject: {},
				isProcessing: false,
				isError: false,
			};
		}
		case "RECEIVED_DELTEK_PROJECT": {
			return {
				deltekProject: deltekAction.payload,
				isProcessing: false,
				isError: false,
			};
		}

		case "FETCH_DELTEK_PROJECT_ERROR": {
			return {
				deltekProject: {},
				isProcessing: false,
				isError: true,
			};
		}

		case "SAVE_DELTEK_PROJECT": {
			return {
				deltekProject: {},
				isProcessing: false,
				isError: false,
			};
		}

		case "SAVED_DELTEK_PROJECT": {
			return {
				deltekProject: deltekAction.payload,
				isProcessing: false,
				isError: false,
			};
		}

		case "SAVE_DELTEK_PROJECT_ERROR": {
			return {
				deltekProject: {},
				isProcessing: false,
				isError: false,
			};
		}

		case "CLEAR_DELTEK_PROJECT": {
			return initialState;
		}

		default: {
			return {
				deltekProject: state.deltekProject,
				isProcessing: false,
				isError: false,
			};
		}
	}
};

export default DeltekProjectReducer;
