const initialState = {
	MyPSP: [],
	isProcessing: false,
	isError: false,
};

const MyPSPReducer = (state = initialState, myPSPAction) => {
	switch (myPSPAction.type) {
		case "FETCH_MYPSP": {
			return {
				MyPSP: [],
				isProcessing: false,
				isError: false,
			};
		}
		case "RECEIVED_MYPSP": {
			return {
				MyPSP: myPSPAction.payload,
				isProcessing: false,
				isError: false,
			};
		}
		case "FETCH_MYPSP_ERROR": {
			return {
				MyPSP: [],
				isProcessing: false,
				isError: true,
			};
		}
		default: {
			return state; /*  {
              MyPSP:state.MyPSP,
              isProcessing:false,
              isError:false
          } */
		}
	}
};

export default MyPSPReducer;
