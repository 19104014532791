const initialState = () => {
	// var item =  localStorage.getItem('user')
	return {
		userObj: {},
	};
};

const AuthenReducer = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_AUTH": {
			return {
				userObj: undefined,
				isSecure: false,
			};
		}
		case "RECEIVED_Authen": {
			/*    if(action.payload===undefined){
                localStorage.removeItem('user')
            }else{
               
            localStorage.setItem('user',JSON.stringify(action.payload));
            } */

			return {
				userObj: action.payload,
				isSecure: action.payload === undefined ? false : true,
			};
		}

		default: {
			return state;
		}
	}
};

export default AuthenReducer;
