/* 
        Project             : Tisdale Analysis Dashboard
        Client              : DWR
        Author Company      : ESA
        Author              : Peter Ross
        Contact             : pross@esassoc.com

*/
import React, { useState } from "react";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Button } from "@material-ui/core";
import { ticketStyles } from "../../../Styles";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";

export default function MenuDrawer(props) {
	const [sOpen, setsOpen] = useState(true);
	const classes = ticketStyles();
	function handleOpen(v) {
		setsOpen(v);
		props.opencallback(v);
	}
	return (
		<Drawer
			variant="permanent"
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: sOpen,
				[classes.drawerClose]: !sOpen,
			})}
			classes={{
				paper: clsx({
					[classes.drawerOpen]: sOpen,
					[classes.drawerClose]: !sOpen,
				}),
			}}
			ModalProps={{
				container: document.getElementById("drawer-container"),
				style: { position: "absolute" },
			}}
			anchor="right">
			<div
				align="right"
				width="100%">
				<Button onClick={() => handleOpen(!sOpen)}>{sOpen ? <NavigateNextIcon /> : <NavigateBeforeIcon />} </Button>
			</div>

			{props.children}
		</Drawer>
	);
}
