const fetch_Project = () => {
	return {
		type: "FETCH_PROJECT",
	};
};

const received_Project = (project) => {
	return {
		type: "RECEIVED_PROJECT",
		payload: project,
	};
};

const fetch_Project_Error = () => {
	return {
		type: "FETCH_PROJECT_ERROR",
	};
};

const clear_Project = () => {
	return {
		type: "CLEAR_PROJECT",
	};
};

const save_Project = () => {
	return {
		type: "SAVE_PROJECT",
	};
};

const saved_Project = (project) => {
	return {
		type: "SAVED_PROJECT",
		payload: project,
	};
};

const save_Project_Error = () => {
	return {
		type: "SAVE_PROJECT_ERROR",
	};
};

const set_Project_ReadOnly = (psp, currentUser) => {
	//function to determine if the rest of the form should be a read only form or not
	// who can edit a psp: creater, PM, reviewer

	let isReadOnly = false;

	if (psp.currentStatus === 2 || psp.currentStatus === 3) {
		//return false if user is a PM or creator
		isReadOnly = psp.projectUsers
			.filter((user) => user.pspRoleId === 1 || user.pspRoleId === 5)
			.find((user) => user.userId.toLowerCase() === currentUser.user.mail.toLowerCase())
			? false
			: true;
	}

	if (isReadOnly) {
		const isProjectReviewer = psp.projectReviews.find(
			(review) => review.reviewer.friendName.toLowerCase() === currentUser.userName?.toLowerCase(),
		)
			? false
			: true;
		isReadOnly = isProjectReviewer;
	}

	return {
		type: "SET_PROJECT_READONLY",
		payload: isReadOnly,
	};
};

const set_Project_Ready_To_Submit = (isReadyToSubmit) => {
	return {
		type: "SET_PROJECT_READY_TO_SUBMIT",
		payload: isReadyToSubmit,
	};
};

const set_Project_Ready_To_Approve = (isReadyToApprove) => {
	return {
		type: "SET_PROJECT_READY_APPROVE",
		payload: isReadyToApprove,
	};
};

const myPSPActions = {
	fetch_Project,
	received_Project,
	fetch_Project_Error,
	clear_Project,
	save_Project,
	saved_Project,
	save_Project_Error,
	set_Project_ReadOnly,
	set_Project_Ready_To_Submit,
	set_Project_Ready_To_Approve,
};

export default myPSPActions