const initialState = {
	deltekUserList: [],
	isProcessing: false,
	isError: false,
};

const DeltekUserListReducer = (state = initialState, userAction) => {
	switch (userAction.type) {
		case "FETCH_DELTEK_USERS": {
			return {
				deltekUserList: [],
				isProcessing: false,
				isError: false,
			};
		}
		case "RECEIVED_DELTEK_USERS": {
			return {
				deltekUserList: userAction.payload,
				isProcessing: false,
				isError: false,
			};
		}

		case "FETCH_DELTEK_USERs_ERROR": {
			return {
				deltekUserList: [],
				isProcessing: false,
				isError: true,
			};
		}

		default: {
			return state;
		}
	}
};

export default DeltekUserListReducer;
