import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { API_URI_BASE } from "../../../config";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { StepFiveStyles } from "../../../Styles";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import VersionCheck from "../../Control/VersionCheck";

const Submit = () => {
	const objProject = useSelector((state) => state.ProjectReducer.projectInfo);
	const projectState = useSelector((state) => state.ProjectReducer.projectState);
	const classes = StepFiveStyles();
	const history = useHistory();

	const handleDownloadButton = async () => {
		window.location.href = API_URI_BASE() + "/report/" + objProject.wbs1;
	};

	return (
		<>
			<VersionCheck />
			<div className={classes.root}>
				<Grid
					container
					spacing={3}>
					<Grid
						item
						xs={12}>
						<Card>
							<CardHeader
								className={classes.cardHeader}
								title="Submitted"
							/>
							<CardContent>
								<Grid
									container
									spacing={4}
									className={classes.card}>
									<Grid
										item
										xs={12}>
										<Typography variant="h6">Thanks for submitting</Typography>
										<br />
										{projectState !== 4 && (
											<>
												<Typography variant="body1">
													Your PSP has been submitted and will be reviewed by a Regional Safety Coordinator.
												</Typography>
												<br />
												<Typography variant="body1">
													You can view your plan, make edits, and once approved, download your Project Safety Plan
													report to distribute a PDF version to field staff. Please make sure to also perform the
													following activities:
													<br />
													<ul>
														<li>
															Remind your project team to read and acknowledge the Approved PSP (once it is approved) in
															e-Safety
														</li>
														<li>Schedule your kick-off call</li>
														<li>Schedule a coordination call with the field team</li>
													</ul>
												</Typography>{" "}
											</>
										)}
										{projectState === 4 && (
											<>
												<Typography variant="body1">
													Your PSP has been submitted. No further action is needed, you can download a copy of your
													completed PSP below.
												</Typography>
												<br />
											</>
										)}
									</Grid>
								</Grid>
								<br />
								<hr />
								{projectState !== 4 && (
									<div className={classes.buttonRight}>
										<Button
											variant="contained"
											color="secondary"
											onClick={() => history.push("/mypsp")}>
											Return to My PSP
										</Button>
										&nbsp;&nbsp;&nbsp;
									</div>
								)}
								{projectState === 4 && (
									<div className={classes.buttonRight}>
										<Button
											variant="contained"
											color="secondary"
											onClick={() => history.push("/")}>
											Home
										</Button>
										&nbsp;&nbsp;&nbsp;
										<Button
											variant="contained"
											color="secondary"
											onClick={handleDownloadButton}>
											Download PSP Report
										</Button>
										&nbsp;&nbsp;&nbsp;
									</div>
								)}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default Submit;
