import React, { useEffect, useState, useContext } from "react";
import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	IconButton,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Chip,
	TextField,
} from "@material-ui/core";
import {
	OpenInBrowserOutlined,
	PictureAsPdfOutlined,
	SettingsBackupRestoreOutlined,
	FaceOutlined,
	PersonAdd,
} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverTwoTone";
import Axios from "axios";
import { RotateSpinner } from "react-spinners-kit";
import { API_URI_BASE } from "../../config";
import { QueryStyles } from "../../Styles";
import { getQueryOptions } from "../../utilities/utilityFunctions";
import MyPSP from "../Control/MyPSP.Component";
import { selects } from "./searchConfig";
import { IS_PRODUCTION } from "../../config";
import { confirmAlert } from "react-confirm-alert";
import HasRole, { keycloakRole } from "../Authentication/HasRole";
import { Consumer as KeycloakConsumer } from "react-keycloak-context";
import bootstrapFromAPI from "../../bootstrapData";
import allActions from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { user } from "../../models/user";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const getStatusText = (sts) => {
	switch (sts) {
		case 1:
			return "In Progress";
		case 2:
			return "Submitted";
		case 3:
			return "Approved";
		case 4:
			return "No Fieldwork";
		default:
			return "Unknown";
	}
};
export default function AdminQuery() {
	const kc = useContext(KeycloakConsumer);
	const classes = QueryStyles();
	const [queryOptions, setQueryOptions] = useState();
	const [busy, setBusy] = useState();
	const [cols, setCols] = useState([]);
	const [gridData, setGridData] = useState([]);
	const [selection, setSelection] = useState();
	const [dlDisabled, setDlDisabled] = useState(false);
	const [open, setOpen] = useState(false);
	const [dialogOptions, setDialogOptions] = useState({
		title: "",
		fieldLabel: "",
	});
	const [projectManager, setProjectManager] = useState(null);
	const [fixedOptions, setFixedOptions] = useState([]);
	const [projectReviewers, setProjectReviewers] = useState([]);
	const dispatch = useDispatch();
	let objProject = useSelector((state) => state.ProjectReducer.projectInfo);
	let users = useSelector((state) => state.DeltekUserListReducer.deltekUserList);
	let activityLevels = useSelector((state) => state.ActivityLevelReducer.activityLevels);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const performQuery = async () => {
		setBusy(true);
		if (selection) {
			let response = await bootstrapFromAPI.getQueryData(selection);
			response.data.forEach((a) => {
				var sorted = a.projectStatuses.sort(function (status1, status2) {
					return new Date(status2.statusDate) - new Date(status1.statusDate);
				});

				a.currentStatus = sorted.length > 0 ? getStatusText(sorted[0].statusId) : "unk";
			});
			setGridData(response.data);
		} else {
			setGridData([]);
		}
		setBusy(false);
	};

	const getMyPSPData = () => {
		var ret = {
			rows: gridData,
			columns: cols,
			mypsptitle: "Search Results",
			style: { height: 800 },
		};
		return ret;
	};

	const confDel = (params) => {
		confirmAlert({
			title: "Delete " + params.row.wbs1,
			message: IS_PRODUCTION
				? "You are deleting from PRODUCTION DATA"
				: "You are deleting from DEVELOPMENT/STAGING DATA",
			buttons: [
				{
					label: "Yes",
					onClick: async () => {
						await bootstrapFromAPI.deletePSP(params.row.wbs1);
						performQuery();
						return;
					},
				},
				{
					label: "No",
					onClick: () => console.log("User backed out of deleting data"),
				},
			],
		});
	};

	const handleControlChange = (source) => {
		var selItems = selection;
		selItems[source.target.name] = source.target.value;
		setSelection(selItems);

		performQuery();
	};

	// TODO: this doesn't work - 4/18 JS
	const downloadFile = () => {
		setDlDisabled(true);
		Axios({
			url: API_URI_BASE() + "/ProjectQuery/FullQueryFile",
			method: "POST",
			data: selection,
			responseType: "blob",
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "PSP_QUERY.csv");
			document.body.appendChild(link);
			link.click();
			setDlDisabled(false);
		});
	};

	const rollBackProject = (params) => {
		confirmAlert({
			title: "Rollback " + params.row.wbs1,
			message: IS_PRODUCTION
				? "You are attempting to roll back a project in the PRODUCTION enviroment. Are you sure?"
				: "You are using staging data, are you sure?",
			buttons: [
				{
					label: "Yes",
					onClick: async () => {
						bootstrapFromAPI.rollBackPSP(params.row.wbs1);
						performQuery();
					},
				},
				{
					label: "No",
					onClick: () => console.log("User backed out of rollback data"),
				},
			],
		});
	};

	const fetchProject = async (wbs1, dialogTitle) => {
		const result = await bootstrapFromAPI.fetchProject(wbs1);

		objProject = { ...result };

		if (dialogTitle === "Add Project Reviewer(s)") {
			const reviewers = objProject.projectReviews
				.filter((pr) => pr.reviewer)
				.map((user) => {
					return {
						userId: user.reviewer.userName,
						fullName: user.reviewer.friendName,
					};
				});
			setFixedOptions(reviewers);
			setProjectReviewers(reviewers);
		}

		const foundUser =
			objProject.projectUsers.find((user) => user.pspRoleId === 1) === undefined
				? user
				: users.find((a) => a.userId === objProject.projectUsers.find((user) => user.pspRoleId === 1).userId);

		if (dialogTitle === "Add Deputy Project Manager(s)") {
			const projectUsers = objProject.projectUsers.filter((user) => user.pspRoleId === 5);
			setFixedOptions(projectUsers);
			setProjectReviewers(projectUsers);
		}

		if (dialogTitle === "Re-Assign Project Manager") {
			setProjectManager(foundUser);
		}

		dispatch(allActions.ProjectActions.saved_Project(objProject));
	};

	const updateDialog = async (params) => {
		await fetchProject(params.row.wbs1, params.field);
		setDialogOptions({
			title: params.field,
			fieldLabel: "Name",
		});
		handleOpen();
	};

	const handleSingleChange = (event, value) => {
		setProjectManager(value);
	};

	const handleMultiplesChange = (event, value) => {
		setProjectReviewers([...fixedOptions, ...value.filter((option) => fixedOptions.indexOf(option) === -1)]);
	};

	const handelSaveData = async () => {
		if (dialogOptions.title === "Add Deputy Project Manager(s)") {
			const filteredDeputyPMs = projectReviewers.filter((option) => fixedOptions.indexOf(option) === -1);

			filteredDeputyPMs.forEach((pm) =>
				objProject.projectUsers.push({
					projectId: objProject.projectId,
					userId: pm.userId,
					pspRoleId: 5,
					fullName: pm.fullName,
				}),
			);

			const response = await bootstrapFromAPI.saveProject(objProject);
			if (response === "OK") {
				handleClose();
				dispatch(allActions.ProjectActions.saved_Project(objProject));
				performQuery();
			}
		}

		if (dialogOptions.title === "Re-Assign Project Manager") {
			let pm = objProject.projectUsers.find((a) => a.pspRoleId === 1);
			if (pm) {
				pm.userId = projectManager.userId;
				pm.fullName = projectManager.fullName;
			}

			const response = await bootstrapFromAPI.saveProject(objProject);
			if (response === "OK") {
				handleClose();
				dispatch(allActions.ProjectActions.saved_Project(objProject));
				performQuery();
			}
		}

		if (dialogOptions.title === "Add Project Reviewer(s)") {
			const filteredReviewers = projectReviewers.filter((option) => fixedOptions.indexOf(option) === -1);

			const requestObject = {
				project: objProject,
				reviewersToAdd: filteredReviewers,
			};

			const response = await bootstrapFromAPI.saveReview(requestObject);
			if (response.status === 200) {
				handleClose();
				dispatch(allActions.ProjectActions.saved_Project(response.data));
				performQuery();
			}
		}
	};

	useEffect(() => {
		const addReviewer = {
			field: "Add Project Reviewer(s)",
			headerName: "+Reviewers",
			width: 90,
			headerAlign: "center",
			align: "center",
			sortable: false,
			filterable: false,
			renderCell: (params) => (
				<IconButton
					key={"projectReviewer" + params._id}
					variant="contained"
					color="primary"
					size="small"
					onClick={() => updateDialog(params)}>
					<PersonAdd />
				</IconButton>
			),
		};

		const assignPM = {
			field: "Re-Assign Project Manager",
			headerName: "Re-Assign PM",
			width: 90,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<strong>
					<IconButton
						key={"reassign-pm" + params._id}
						variant="contained"
						color="primary"
						size="small"
						onClick={() => updateDialog(params)}>
						<FaceOutlined />
					</IconButton>
				</strong>
			),
		};

		const addDPM = {
			field: "Add Deputy Project Manager(s)",
			headerName: "+DPM",
			width: 90,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<strong>
					<IconButton
						key={"dpm" + params._id}
						variant="contained"
						color="primary"
						size="small"
						onClick={() => updateDialog(params)}>
						<PersonAdd />
					</IconButton>
				</strong>
			),
		};

		const rollBack = {
			field: "siteid",
			headerName: "Roll Back",
			width: 90,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<strong>
					<IconButton
						key={"rollback" + params._id}
						variant="contained"
						color="primary"
						size="small"
						onClick={() => rollBackProject(params)}>
						<SettingsBackupRestoreOutlined />
					</IconButton>
				</strong>
			),
		};

		const downloadReport = {
			field: "site",
			headerName: "Report",
			width: 90,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<strong>
					<IconButton
						key={"download" + params._id}
						variant="contained"
						color="primary"
						size="small"
						onClick={() => window.open(`${API_URI_BASE()}/Report/${params.row.wbs1}`, "_blank")}>

						<PictureAsPdfOutlined />
					</IconButton>
				</strong>
			),
		};

		const step1_col = {
			field: "siteidx",
			headerName: "Go to",
			width: 90,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<strong>
					<IconButton
						key={"siteidx" + params._id}
						variant="contained"
						color="primary"
						size="small"
						onClick={() => {
							window.location.href = `/step1/${params.row.wbs1}`;
						}}>
						<OpenInBrowserOutlined />
					</IconButton>
				</strong>
			),
		};

		const delete_Col = {
			field: "projectId",
			width: 90,
			headerName: "Delete",
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<strong>
					<IconButton
						key={"projectId" + params._id}
						variant="contained"
						color="primary"
						size="small"
						onClick={() => confDel(params)}>
						<DeleteForeverTwoToneIcon />
					</IconButton>
				</strong>
			),
		};

		const gridCols = [
			{ field: "wbs1", headerName: "Project Number", width: 175 },
			{ field: "projectName", headerName: "Project Name", width: 400 },
			{ field: "businessGroup", headerName: "Business Group", width: 175 },
			{
				field: "lastUpdated",
				headerName: "Date Updated",
				width: 200,
				type: "dateTime",
				valueGetter: ({ value }) => value && new Date(value),
			},
			{ field: "currentStatus", headerName: "Current Status", width: 150 },
		];

		gridCols.push(downloadReport);
		gridCols.push(step1_col);

		keycloakRole(kc, "admin-psp-delete") && gridCols.push(delete_Col);
		keycloakRole(kc, "admin-psp-rollback") && gridCols.push(rollBack);
		keycloakRole(kc, "admin-reassign-pm") && gridCols.push(assignPM);
		keycloakRole(kc, "admin-reassign-pm") && gridCols.push(addDPM);
		keycloakRole(kc, "admin-add-reviewer") && gridCols.push(addReviewer);
		setCols(gridCols);

		const loadData = async () => {
			var opts = await getQueryOptions();
			if (!selection) {
				var selArr = {};
				selects(opts, activityLevels).forEach((a) => {
					selArr[a.id] = [];
				});
				setSelection(selArr);
			}
			setQueryOptions(opts);
		};

		loadData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return queryOptions ? (
		<HasRole
			role="admin-queryPage"
			errorMessage="You don't have access to this feature">
			{" "}
			<>
				<Grid container>
					{selects(queryOptions, activityLevels).map((a) => (
						<Grid
							item
							xs={2}
							lg={4}
							key={a.label + "-tag"}>
							<FormControl className={classes.formControl}>
								<InputLabel id={a.label + "label"}>{a.label}</InputLabel>
								<Select
									multiple
									id={a.id}
									value={selection[a.id]}
									name={a.id}
									onChange={handleControlChange}
									labelId={a.label + "label"}
									style={{
										minWidth: 200,
										alignItems: "baseline",
										textAlign: "center",
										alignSelf: "center",
									}}>
									{a.options.map((b) => (
										<MenuItem
											key={"id-" + b.value}
											value={b.value}>
											{b.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					))}
					<Grid
						item
						xs={12}
						lrg={12}>
						{busy && (
							<RotateSpinner
								size={45}
								color="#686769"
							/>
						)}
					</Grid>
				</Grid>
				<>
					<Button
						onClick={downloadFile}
						disabled={dlDisabled}>
						Download
					</Button>
					{gridData && (
						<MyPSP
							objPSPList={getMyPSPData()}
							hidePagination={true}
						/>
					)}
				</>
			</>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle
					id="alert-dialog-title"
					style={{ backgroundColor: "#004361", color: "#FFFFFF" }}>
					{dialogOptions.title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<Grid
							container
							spacing={3}>
							<Grid
								item
								xs={6}>
								<TextField
									disabled={true}
									value={objProject.projectName}
									id="projectName"
									fullWidth={true}
									label="Project Name"
									className={classes.projectName}
								/>
							</Grid>
							<Grid
								item
								xs={6}>
								<TextField
									disabled={true}
									value={objProject.businessGroup}
									id="projectName"
									fullWidth={true}
									label="Business Group"
									className={classes.projectName}
								/>
							</Grid>
							<Grid
								item
								xs={12}>
								{dialogOptions.title === "Re-Assign Project Manager" ? (
									<Autocomplete
										id="projectManager"
										options={users}
										getOptionLabel={(option) => option.fullName}
										value={projectManager}
										onChange={handleSingleChange}
										renderInput={(params) => (
											<TextField
												{...params}
												label={dialogOptions.fieldLabel}
											/>
										)}
									/>
								) : (
									<Autocomplete
										multiple
										id="selectMultiples"
										value={projectReviewers}
										onChange={handleMultiplesChange}
										options={users}
										getOptionLabel={(option) => option.fullName}
										renderTags={(tagValue, getTagProps) =>
											tagValue.map((option, index) => (
												<Chip
													label={option.fullName}
													{...getTagProps({ index })}
													disabled={fixedOptions.indexOf(option) !== -1}
												/>
											))
										}
										style={{ width: "100%" }}
										renderInput={(params) => (
											<TextField
												{...params}
												label={dialogOptions.fieldLabel + "(s)"}
												variant="standard"
											/>
										)}
									/>
								)}
							</Grid>
							<Grid
								item
								xs={12}>
								<Button
									variant="contained"
									color="secondary"
									onClick={handelSaveData}>
									Save
								</Button>
							</Grid>
						</Grid>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</HasRole>
	) : (
		<div style={{ padding: "2rem" }}>
			<RotateSpinner
				size={45}
				color="#686769"
			/>
		</div>
	);
}
