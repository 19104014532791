/* 
		Project             : eSafety 2.0
		Client              : Internal
		Author Company      : ESA
		Author              : Peter Ross
		Contact             : pross@esassoc.com

*/
import { Fab, Grid, Typography, Button } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CheckIcon from "@material-ui/icons/Check";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveReview } from "../../../redux/reducers/ticketReducer";
import { ticketStyles } from "../../../Styles";
import ESAComment from "./ESAComment";
import MenuDrawer from "./MenuDrawer";
import bootstrapFromAPI from "../../../bootstrapData";
import { useHistory } from "react-router-dom";

export default function TicketBar({ cb_addChild, projectx, reval }) {
	const currentPsp = useSelector((state) => state.ProjectReducer.projectInfo);
	const isReviewMode = useSelector((state) => state.ProjectReducer);
	const dispatch = useDispatch();
	const [,] = useState(reval);
	const [dOpen, setDOpen] = useState(true);
	const classes = ticketStyles();
	const currentUser = useSelector((state) => state.AuthenReducer.userObj);
	const [isPm, setIsPm] = useState(false);
	const [manBlock, setManBlock] = useState(false);
	const [openComments, setOpenComments] = useState([]);
	const [pendingComments, setPendingComments] = useState([]);
	const [closedComments, setClosedComments] = useState([]);
	const currentScopes = useSelector((state) => state.TicketReducer.currentScopes);

	const review = useSelector((state) => state.TicketReducer.activeReview); // new
	const history = useHistory(); // new

	useEffect(() => {
		//setReview
		if (!currentPsp?.projectReviews) return;
		let userName = "none";
		if (currentUser.user.mail) userName = currentUser.user.mail.toLowerCase(); // "JOToole@esassoc.com";

		let reviews = currentPsp.projectReviews.filter((a) =>
			a.reviewer ? a.reviewer.userName.toLowerCase() === userName.toLowerCase() : true === false,
		);
		if (reviews.length > 0) {
			dispatch(setActiveReview(reviews[0]));
		} else {
			dispatch(setActiveReview(undefined));
		}

		let validRoles = [1, 5];
		if (isReviewMode === false) {
			setManBlock(true); //should block showing when it's submitted
		}

		if (currentUser.user.mail) userName = currentUser.user.mail.toLowerCase(); // "JOToole@esassoc.com";
		let prjUsers = currentPsp.projectUsers
			? currentPsp.projectUsers.filter(
				(a) => a.userId.toLowerCase() === userName.toLowerCase() && validRoles.indexOf(a.pspRoleId) > -1,
			)
			: [];

		if (prjUsers.length > 0) {
			setIsPm(true);
		}

		const commentsOpen = currentPsp.tickets.filter((a) => a.statusid === 1);
		const commentsPending = currentPsp.tickets.filter((a) => a.statusid === 2);
		const commentsClosed = currentPsp.tickets.filter((a) => a.statusid === 3);

		setOpenComments(commentsOpen);
		setPendingComments(commentsPending);
		setClosedComments(commentsClosed);
	}, [currentPsp, currentUser, isReviewMode, dispatch]);

	function menuMode(openClose) {
		setDOpen(openClose);
	}

	function handleAddChild(ticket) {
		if (cb_addChild) {
			cb_addChild(ticket);
		}
	}

	// inital thoughts, I think we can ride of the ShowMe, if we change the routing
	// it could go a few ways, "Add Review" column could be added to Submitted MyPSP
	// leave it as Edit,

	const ShowMe = () => {
		//NOTE: this could use a refactor, seems like a lot of stuff that is already check via redux

		if (!window.location.href.includes("step")) {
			return false;
		}

		if (manBlock) {
			return false;
		}

		if (currentPsp?.reviewMode === false) return false;

		let show = true;
		if (!currentPsp?.projectStatuses) return false;
		currentPsp.projectStatuses.sort(function (a, b) {
			return b.projectStatusId - a.projectStatusId;
		});

		if (currentPsp.projectStatuses[0].statusId !== 2) {
			return false;
		}

		//If the currentPsp is null don't show
		if (currentPsp.projectid === null) {
			return false;
		}
		let userName = "none";

		if (currentUser.user.mail) userName = currentUser.user.mail.toLowerCase(); // "JOToole@esassoc.com";

		//Does the currentPsp has any reviews
		if (!currentPsp.projectReviews) {
			return false;
		} else {
			console.log("currentPsp has reviews", currentPsp.projectReviews);
		}
		//Roles that can see the PSP regardless of review
		//Now that currentPsp as reivew does the current user
		//match with any of the reviewer
		let reviews = currentPsp.projectReviews.filter((a) =>
			a.reviewer ? a.reviewer.userName.toLowerCase() === userName.toLowerCase() : false,
		);

		if (isPm) return true;

		if (reviews.length === 0) {
			console.log("USER HAS NO REVIEWS FOR THIS PROJECT");
			return false;
		} else {
			dispatch(setActiveReview(reviews[0]));
		}

		//Set the reviews to the first review
		return show;
	};

	const noReviewDisplay = () => {
		return (
			<div className={classes.reviewContainer}>
				<Accordion
					className={classes.ticketBarGrid}
					style={{ padding: 0, verticalAlign: "text-bottom" }}>
					<AccordionSummary className={classes.ticketBarTitle}>Review and Comment</AccordionSummary>
					<AccordionDetails>
						<Grid
							container
							id={"grid-ticket-bar-cont"}
							alignContent={"center"}
							width={400}
							style={{ maxWidth: 400, padding: 2 }}>
							<Grid
								item
								lg={12}
								className={classes.reviewDisplayHeader2}>
								Review Summary
							</Grid>
							<Grid
								item
								xs={4}
								className={classes.reviewDisplayHeader}>
								Reviewer Name
							</Grid>
							<Grid
								item
								xs={4}
								style={{ textAlign: "center" }}
								className={classes.reviewDisplayHeader}>
								Reviewed?
							</Grid>
							<Grid
								item
								xs={4}
								className={classes.reviewDisplayHeader}>
								Accepted
							</Grid>
							{currentPsp &&
								currentPsp.projectReviews &&
								currentPsp.projectReviews.map((a) => (
									<React.Fragment key={a.reviewer.friendName + "--projectReviewer"}>{reviewItem(a)}</React.Fragment>
								))}
						</Grid>
					</AccordionDetails>
				</Accordion>
			</div>
		);
	};

	const reviewItem = (revItem) => {
		if (revItem.reviewer.isAdmin === true) return <></>;
		return (
			<>
				<Grid
					item
					xs={4}>
					{revItem.reviewer.friendName}
					{revItem.reviewer.canApprove && <>*</>}
				</Grid>
				<Grid
					item
					xs={4}
					style={{ textAlign: "center" }}>
					{revItem.approved ? <ThumbUpIcon /> : <HourglassEmptyIcon />}
				</Grid>
				<Grid
					item
					xs={4}>
					{revItem.approvalDate && new Date(revItem.approvalDate).toLocaleDateString("en-US")}
				</Grid>
			</>
		);
	};

	const TicketIndicator = (status, count) => {
		return (
			<div
				style={{ textAlign: "center" }}
				id={"status-div"}>
				<Fab
					size="small"
					className={classes["ticketStatus" + status]}>
					{count}
				</Fab>{" "}
				<Typography> {status.toUpperCase()} </Typography>
			</div>
		);
	};

	function setReviewed() {
		let username = currentUser.user.mail.toLowerCase();
		console.log(currentPsp, "currentPsp")
		let userReview = currentPsp.projectReviews.find(
			(review) => review.reviewer.userName.toLowerCase() === username,
		);

		if (!userReview) {
			alert("Could not find any reviews for " + username);
		} else {
			userReview = {
				...userReview,
				approved: true,
			};

			const updatedReviews = currentPsp.projectReviews.map((review) => {
				if (review.reviewer.userName.toLowerCase() === username) {
					return userReview;
				} else {
					return review;
				}
			});

			const updatedPsp = {
				...currentPsp,
				projectReviews: updatedReviews,
			};

			bootstrapFromAPI.approvePSP(updatedPsp.wbs1, userReview).then((response) => {
				if (response === "OK") {
					history.push("/mypsp");
				}
			});
		}

	}

	const SOSCButtons = () => {
		return review.approved ? (
			<div
				style={{
					textAlign: "center",
					height: "7vh",
					width: "100vw",
				}}>
				{" "}
				<Typography variant="h4">Approved</Typography>
			</div>
		) : (
			<div
				style={{
					color: "#007C90",
					fontWeight: 600,
					alignItems: "center",
					textAlign: "center",
					height: "7vh",
					width: "100%",
				}}>
				<Fab
					size="small"
					onClick={setReviewed}>
					<CheckIcon htmlColor="#00A69C" />
				</Fab>
				<Typography> Approve </Typography>
			</div>
		);
	};

	const SMEButtons = () => {
		// THIS IS FOR SME's only. SME's CAN ONLY MARK AS REVIEWED, NOT APPROVE OR APPROVE WITH COMMENTS
		return isPm ? (
			<></>
		) : (
			<Grid
				item
				sm={12}
				style={{ textAlign: "center" }}>
				{review && review.approved ? (
					<Typography>Reviewed</Typography>
				) : (
					<Button onClick={setReviewed}>Mark as Reviewed</Button>
				)}{" "}
			</Grid>
		);
	};

	return !ShowMe() ? (
		<></>
	) : (
		<MenuDrawer
			opencallback={menuMode}
			id={"ticket-bar-1"}
			className={classes.ticketBar}
			open={dOpen}
			variant="persistent"
			anchor="right">
			{dOpen ? (
				<>
					<Grid
						id={"grid-ticket-bar-cont"}
						alignContent={"center"}
						spacing={3}
						container
						border={3}
						className={classes.ticketBarGrid}>
						<Grid
							item
							sm={12}>
							{noReviewDisplay()}
						</Grid>
						<Grid
							item
							sm={12}>
							{console.log(review && review.reviewer.canApprove ? "SOSCButtons()" : "SMEButtons()")}
							{review && review.reviewer.canApprove ? SOSCButtons() : SMEButtons()}
						</Grid>
						<Grid
							item
							sm={12}>
							<div className={classes.pageCommentContainer}>
								<Grid
									id={"grid-ticket-bar-cont"}
									spacing={3}
									container
									border={3}
									className={classes.ticketBarGrid}
									alignContent={"center"}
									style={{ textAlign: "left" }}>
									<Grid
										item
										sm={12}
										className={classes.ticketBarTitle}
										style={{ fontSize: 20 }}>
										Comments
									</Grid>
									<Grid
										item
										sm={4}
										className={classes.ticketCounter}>
										{TicketIndicator("open", openComments.length)}
									</Grid>
									<Grid
										item
										sm={4}
										className={classes.ticketCounter}>
										{" "}
										{TicketIndicator("pending", pendingComments.length)}
									</Grid>
									<Grid
										item
										sm={4}
										className={classes.ticketCounter}>
										{TicketIndicator("closed", closedComments.length)}
									</Grid>
									<Grid
										item
										sm={12}>
										{currentPsp &&
											currentPsp.tickets &&
											currentPsp.tickets
												.filter((tic) => !tic.parentTicketId && currentScopes.find((a) => a === tic.ticketScope))
												.map((a) => (
													<div key={"g-ticket" + a.ticketId}>
														<Grid
															item
															sm={12}
															textAlign="left"
															className={classes.ticketContainer}>
															<ESAComment
																cb_createChild={handleAddChild}
																ticket={a}
															/>
														</Grid>
													</div>
												))}
									</Grid>
								</Grid>
								<br />
							</div>
						</Grid>
					</Grid>
				</>
			) : (
				<>
					<Typography
						variant="h6"
						style={{
							transform: "rotate(90deg)",
							whiteSpace: "nowrap",
							top: -100,
							display: "block",
						}}>
						Review and Comment
					</Typography>
				</>
			)}
		</MenuDrawer>
	);
}
