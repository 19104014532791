const fetch_Deltek_Project = () => {
	return {
		type: "FETCH_DELTEK_PROJECT",
	};
};

const received_Deltek_Project = (deltekProject) => {
	return {
		type: "RECEIVED_DELTEK_PROJECT",
		payload: deltekProject,
	};
};

const fetch_Deltek_Project_Error = () => {
	return {
		type: "FETCH_DELTEK_PROJECT_ERROR",
	};
};

const save_Deltek_Project = () => {
	return {
		type: "SAVE_DELTEK_PROJECT",
	};
};

const saved_Deltek_Project = (deltekProject) => {
	return {
		type: "SAVED_DELTEK_PROJECT",
		payload: deltekProject,
	};
};

const save_Deltek_Project_Error = () => {
	return {
		type: "SAVE_DELTEK_PROJECT_ERROR",
	};
};

const clear_Deltek_Project = () => {
	return {
		type: "CLEAR_DELTEK_PROJECT",
	};
};

const deltek_actions = {
	fetch_Deltek_Project,
	received_Deltek_Project,
	fetch_Deltek_Project_Error,
	save_Deltek_Project,
	saved_Deltek_Project,
	save_Deltek_Project_Error,
	clear_Deltek_Project,
};

export default deltek_actions;
