import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { useSelector, useDispatch } from "react-redux";
import allActions from "../../redux/actions";
import { contact } from "../../models/contact";
import TextField from "@material-ui/core/TextField";

import { InputLabel } from "@material-ui/core";
import { TextareaAutosize } from "@material-ui/core";

import { StepFourStyles } from "../../Styles";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

//import bootstrapFromAPI from '../../bootstrapData';

const SiteAccess = () => {
	const classes = StepFourStyles();
	const [, setState] = useState({});
	const dispatch = useDispatch();
	const objProject = useSelector((state) => state.ProjectReducer.projectInfo);
	//Handle read only
	const isReadOnly = useSelector((state) => state.ProjectReducer.isReadOnly);

	const objSite = objProject.site;
	const [siteContact, setSiteContact] = useState(
		objProject.projectContacts.find((obj) => obj.contact.contactTypeId === 1) === undefined
			? contact
			: objProject.projectContacts.find((obj) => obj.contact.contactTypeId === 1).contact,
	);

	//Add or replacing site contact Info
	const handleContact = (event) => {
		siteContact.contactTypeId = 1;
		siteContact[event.target.id] = event.target.value;

		//Handle contact first
		if (objProject.projectContacts.find((obj) => obj.contact.contactTypeId === 1) === undefined) {
			objProject.projectContacts.push({ contact: siteContact });
		} else {
			objProject.projectContacts.find((obj) => obj.contact.contactTypeId === 1).contact = siteContact;
		}

		//console.log(siteContact);
		dispatch(allActions.ProjectActions.saved_Project(objProject));
		setSiteContact(siteContact);
		setState({});
	};

	const handleCheckBox = (event) => {
		if (event.target.checked) {
			objSite[event.target.id] = true;
		} else {
			objSite[event.target.id] = false;
		}

		if (event.target.id) {
			objSite.multiPersonRq = true;
		} else {
			objSite.multiPersonRq = false;
		}

		objProject.site = objSite;
		dispatch(allActions.ProjectActions.saved_Project(objProject));
		setState({});
	};

	const handleChange = (event) => {
		objSite[event.target.id] = event.target.value;
		objProject.site = objSite;
		dispatch(allActions.ProjectActions.saved_Project(objProject));
		setState({});
	};

	return (
		<>
			<Typography variant="h6">Site Access Control / Communication</Typography>
			<FormControlLabel
				control={
					<Checkbox
						checked={objSite.checkInRq === true}
						onChange={handleCheckBox}
						id="checkInRq"
						disabled={isReadOnly}
					/>
				}
				label="Check this box if communication is required with the PM or safety officer."
			/>
			<br />
			<br />
			<Grid
				container
				spacing={4}>
				<Grid
					item
					xs={4}>
					<FormControlLabel
						control={
							<Checkbox
								checked={objSite.gateAccessRq === true}
								onChange={handleCheckBox}
								id="gateAccessRq"
								disabled={isReadOnly}
							/>
						}
						label="Check this box if gate access required."
					/>
				</Grid>
				<Grid
					item
					xs={4}>
					<TextField
						id="accessCode"
						onChange={handleChange}
						value={objSite.accessCode !== null ? objSite.accessCode : ""}
						fullWidth={true}
						label="Access Code"
						variant="outlined"
						disabled={isReadOnly}
					/>
				</Grid>
				<Grid
					item
					xs={4}>
					<TextField
						id="gateLocation"
						onChange={handleChange}
						value={objSite.gateLocation !== null ? objSite.gateLocation : ""}
						fullWidth={true}
						label="Gate Location"
						variant="outlined"
						disabled={isReadOnly}
					/>
				</Grid>
			</Grid>
			<br />
			<Typography variant="body1">
				Enter contact information of entity providing access. Identify available communication (cell, satellite phone,
				etc.)
			</Typography>
			<br />
			<Grid
				container
				spacing={4}>
				<Grid
					item
					xs={4}>
					<TextField
						id="contactName"
						onChange={handleContact}
						value={siteContact.contactName}
						fullWidth={true}
						label="Contact Name"
						variant="outlined"
						disabled={isReadOnly}
					/>
				</Grid>
				<Grid
					item
					xs={4}>
					<TextField
						id="contactCell"
						onChange={handleContact}
						value={siteContact.contactCell}
						fullWidth={true}
						label="Cell Phone"
						variant="outlined"
						disabled={isReadOnly}
					/>
				</Grid>
				<Grid
					item
					xs={4}>
					<TextField
						id="contactSatPhone"
						onChange={handleContact}
						value={siteContact.contactSatPhone}
						fullWidth={true}
						label="Satellite Phone"
						variant="outlined"
						disabled={isReadOnly}
					/>
				</Grid>
				<Grid
					item
					xs={12}>
					<InputLabel>Other available communication</InputLabel>
					<br />
					<TextareaAutosize
						className={classes.fullWidthTextArea}
						maxRows={4}
						minRows={4}
						variant="outlined"
						id="contactOtherInfo"
						onChange={handleContact}
						value={siteContact.contactOtherInfo}
						disabled={isReadOnly}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default SiteAccess;
