import React, { useEffect, useState } from "react";
import { GridToolbar, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Snackbar, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, MenuItem, Chip, Slider, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import bootstrapData from "../../../bootstrapData"; // Make sure to import the bootstrapData functions

const theme = createTheme({
    palette: {
        secondary: {
            main: "#004361",
        },
        primary: {
            main: "#1976d2",
        },
    },
    typography: {
        h6: {
            fontWeight: 600,
        },
        body1: {
            fontWeight: 500,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: 8,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 16,
                },
            },
        },
    },
});

const ManageGuidanceDocuments = () => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [guidanceDocuments, setGuidanceDocuments] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentDocument, setCurrentDocument] = useState(null);

    useEffect(() => {
        document.title = "Manage Guidance Documents";
        bootstrapData.getGuidanceDocuments().then((data) => {
            // Ensure 'lastUpdated' fields are converted to Date objects
            const formattedData = data.map(doc => ({
                ...doc,
                lastUpdated: doc.lastUpdated ? new Date(doc.lastUpdated) : null,
            }));
            setGuidanceDocuments(formattedData);
        });
    }, []);

    const handleAddGuidanceDocument = (newDocument) => {
        bootstrapData.addGuidanceDocument(newDocument).then(() => {
            setSnackbarMessage("Guidance Document added successfully");
            setOpenSnackbar(true);
            bootstrapData.getGuidanceDocuments().then((data) => {
                const formattedData = data.map(doc => ({
                    ...doc,
                    lastUpdated: doc.lastUpdated ? new Date(doc.lastUpdated) : null,
                }));
                setGuidanceDocuments(formattedData);
            });
        });
    };

    const handleUpdateGuidanceDocument = (updatedDocument) => {
        bootstrapData.updateGuidanceDocument(updatedDocument).then(() => {
            setSnackbarMessage("Guidance Document updated successfully");
            setOpenSnackbar(true);
            bootstrapData.getGuidanceDocuments().then((data) => {
                const formattedData = data.map(doc => ({
                    ...doc,
                    lastUpdated: doc.lastUpdated ? new Date(doc.lastUpdated) : null,
                }));
                setGuidanceDocuments(formattedData);
            });
        });
    };

    const handleDeleteGuidanceDocument = (documentId) => {
        bootstrapData.deleteGuidanceDocument(documentId).then(() => {
            setSnackbarMessage("Guidance Document deleted successfully");
            setOpenSnackbar(true);
            bootstrapData.getGuidanceDocuments().then((data) => {
                const formattedData = data.map(doc => ({
                    ...doc,
                    lastUpdated: doc.lastUpdated ? new Date(doc.lastUpdated) : null,
                }));
                setGuidanceDocuments(formattedData);
            });
        });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleProcessRowUpdate = (newRow, oldRow) => {
        handleUpdateGuidanceDocument(newRow);
        return newRow;
    };

    const handleEditClick = (params) => {
        setCurrentDocument(params.row);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setCurrentDocument(null);
    };

    const handleDialogSave = () => {
        if (currentDocument._id) {
            handleUpdateGuidanceDocument(currentDocument);
        } else {
            handleAddGuidanceDocument(currentDocument);
        }
        setDialogOpen(false);
    };

    const handleDialogChange = (field, value) => {
        setCurrentDocument((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            handleDialogChange('filename', file.name);
        }
    };

    const columns = [
        {
            field: "actions",
            headerName: "Actions",
            type: "actions",
            sortable: false,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => handleDeleteGuidanceDocument(params.id)}
                />,
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    onClick={() => handleEditClick(params)}
                />,
            ],
        },
        { field: "_id", headerName: "ID", hide: true, sortable: true },
        { field: "gdId", headerName: "Guidance Document ID", width: 200, editable: true, sortable: true },
        { field: "gdName", headerName: "Name", width: 200, editable: true, sortable: true },
        { field: "gdDescription", headerName: "Description", width: 300, editable: true, sortable: true },
        { field: "resourceId", headerName: "Resource ID", width: 200, editable: true, sortable: true },
        { field: "gdcategoryId", headerName: "Category ID", width: 200, editable: true, sortable: true },
        { field: "defaultChecked", headerName: "Default Checked", width: 150, editable: true, sortable: true, type: 'boolean' },
        { field: "tooltip", headerName: "Tooltip", width: 200, editable: true, sortable: true },
        {
            field: "filename",
            headerName: "Filename",
            width: 200,
            editable: true,
            sortable: true,
            renderCell: (params) => (
                <Chip label={params.value} />
            ),
        },
        { field: "gdcategory", headerName: "Category", width: 200, editable: true, sortable: true },
        { field: "resource", headerName: "Resource", width: 200, editable: true, sortable: true },
        { field: "projectGuidanceDocuments", headerName: "Project Guidance Documents", width: 300, editable: true, sortable: true },
        { field: "lastUpdated", headerName: "Last Updated", width: 200, editable: true, sortable: true, type: 'date' },
        { field: "version", headerName: "Version", width: 150, editable: true, sortable: true },
        { field: "gdNumber", headerName: "Number", width: 150, editable: true, sortable: true },
        {
            field: "rating",
            headerName: "Rating",
            width: 150,
            editable: true,
            sortable: true,
            renderCell: (params) => (
                <Select value={params.value} onChange={(e) => handleDialogChange('rating', e.target.value)}>
                    {Array.from({ length: 10 }, (_, i) => i + 1).map((value) => (
                        <MenuItem key={value} value={value}>{value}</MenuItem>
                    ))}
                </Select>
            ),
        },
        { field: "ratingExplation", headerName: "Rating Explanation", width: 300, editable: true, sortable: true },

    ];

    return (
        <ThemeProvider theme={theme}>
            <DataGridPro
                rows={guidanceDocuments}
                columns={columns}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            _id: false,
                        },
                    },
                }}
                components={{
                    Toolbar: GridToolbar,
                }}
                filterModel={{
                    items: [
                        { columnField: "gdName", operatorValue: "contains", value: "" },
                        { columnField: "gdDescription", operatorValue: "contains", value: "" },
                        { columnField: "resourceId", operatorValue: "=", value: "" },
                        { columnField: "gdcategoryId", operatorValue: "=", value: "" },
                    ],
                }}
                sortModel={[
                    { field: "gdName", sort: "asc" },
                    { field: "gdDescription", sort: "asc" },
                    { field: "resourceId", sort: "asc" },
                    { field: "gdcategoryId", sort: "asc" },
                ]}
                sortingOrder={['asc', 'desc']}
                disableMultipleColumnsSorting={false}
                pagination
                pageSize={10}
                checkboxSelection
                processRowUpdate={handleProcessRowUpdate}
                onProcessRowUpdateError={(error) => console.error(error)}
                componentsProps={{
                    toolbar: {
                        density: "compact",
                        columns: ["filter", "columns", "export"],
                    },
                }}
                experimentalFeatures={{ newEditingApi: true }}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    const newDocument = {
                        gdId: guidanceDocuments.length + 1,
                        gdName: "",
                        gdDescription: "",
                        resourceId: "",
                        gdcategoryId: 0,
                        defaultChecked: false,
                        tooltip: "",
                        filename: "",
                        gdcategory: "",
                        resource: "",
                        projectGuidanceDocuments: [],
                        lastUpdated: new Date(),
                        version: 1,
                        gdNumber: 0,
                        rating: 1,
                        ratingExplation: ""
                    };
                    setCurrentDocument(newDocument);
                    setDialogOpen(true);
                }}
            >
                Add Guidance Document
            </Button>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Edit Guidance Document</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Name"
                        value={currentDocument?.gdName || ''}
                        onChange={(e) => handleDialogChange('gdName', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Description"
                        value={currentDocument?.gdDescription || ''}
                        onChange={(e) => handleDialogChange('gdDescription', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Resource ID"
                        value={currentDocument?.resourceId || ''}
                        onChange={(e) => handleDialogChange('resourceId', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Category ID"
                        value={currentDocument?.gdcategoryId || ''}
                        onChange={(e) => handleDialogChange('gdcategoryId', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Tooltip"
                        value={currentDocument?.tooltip || ''}
                        onChange={(e) => handleDialogChange('tooltip', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="contained-button-file"
                        type="file"
                        onChange={handleFileChange}
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" color="primary" component="span">
                            Upload File
                        </Button>
                    </label>
                    <TextField
                        label="Filename"
                        value={currentDocument?.filename || ''}
                        fullWidth
                        margin="dense"
                        disabled
                    />
                    <TextField
                        label="Category"
                        value={currentDocument?.gdcategory || ''}
                        onChange={(e) => handleDialogChange('gdcategory', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Resource"
                        value={currentDocument?.resource || ''}
                        onChange={(e) => handleDialogChange('resource', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Project Guidance Documents"
                        value={currentDocument?.projectGuidanceDocuments ? currentDocument.projectGuidanceDocuments.join(', ') : ''}
                        onChange={(e) => handleDialogChange('projectGuidanceDocuments', e.target.value.split(', '))}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Version"
                        value={currentDocument?.version || ''}
                        onChange={(e) => handleDialogChange('version', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Number"
                        value={currentDocument?.gdNumber || ''}
                        onChange={(e) => handleDialogChange('gdNumber', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <Typography gutterBottom>Rating</Typography>
                    <Slider
                        value={currentDocument?.rating || 1}
                        onChange={(e, value) => handleDialogChange('rating', value)}
                        aria-labelledby="continuous-slider"
                        step={1}
                        marks
                        min={1}
                        max={10}
                        valueLabelDisplay="auto"
                    />
                    <TextField
                        label="Rating Explanation"
                        value={currentDocument?.ratingExplation || ''}
                        onChange={(e) => handleDialogChange('ratingExplation', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDialogSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default ManageGuidanceDocuments;
