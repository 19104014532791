/*
	Author : Peter Ross
	Description : Simple header for PSP
*/
import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import bootstrapFromAPI from "../../bootstrapData";
import { IsDevCopy } from "../../config";
import allActions from "../../redux/actions";
import { dataStore } from "../../redux/reduxStore";
import { isAdmin } from "../../utilities/utilityFunctions";

import PSPMenu from "./MenuComponent";
import { Reviewers } from "../../utilities/utilityFunctions";


export default function Header({ bubbleEvt }) {
	const authenticated = useSelector((state) => state.AuthenReducer.isSecure);
	const userObj = useSelector((state) => state.AuthenReducer.userObj);
	const [, setAdmin] = useState(false);
	const [, setUL] = useState([]);
	const [, setVersions] = useState();


	useEffect(() => {
		if (!userObj) {
			console.log("Unk user");
			dataStore.dispatch(allActions.AuthenActions.received_Authen(undefined)); //to set secure to false
		}
		bootstrapFromAPI.getVersionInfo().then((vers) => {
			setVersions(vers);
		});
		if (userObj.user.mail) isAdmin(userObj.user.mail.toLowerCase()).then((xadmin) => setAdmin(xadmin));
		Reviewers().then((resp) => setUL(resp));
	}, [userObj]);

	return (
		<div>
			<div style={{ padding: 10 }}>
				<table width="100%">
					<tbody>
						<tr>
							<td valign="middle">
								<div style={{ display: "inline-flex" }}>
									<img
										src={require("../../images/Header_Icon_Hardhat_Circle.png")}
										alt="Hard Hat"
									/>
									&nbsp;&nbsp;
									<Typography
										variant="h4"
										style={{
											color: IsDevCopy() ? "#e38d02" : "#666666",
											marginTop: 10,
										}}>
										e-Safety System
									</Typography>
									{IsDevCopy() && <>Development</>}
								</div>
							</td>
							<td align="right" valign="bottom">
								<Grid container>
									{/* <Grid
										item
										md={4}>
										<Consumer>
											{(token) => (
												<>
													<Grid
														item
														md={1}>
														{" "}
														<HasRole role="admin-impersonate">
															{" "}
															<FormControl>
																<InputLabel id="demo-simple-select-label"> {userObj.user.displayName}</InputLabel>
																<Select
																	id="demo-simple-select"
																	onChange={radioChange}
																	value=""
																	style={{ width: 200, color: "red" }}>
																	{userList.map((user) => (
																		<MenuItem
																			value={user}
																			key={user._id}>
																			{user.friendName}
																		</MenuItem>
																	))}
																</Select>
															</FormControl>
														</HasRole>
													</Grid>
												</>
											)}
										</Consumer>
									</Grid> */}

									<Grid md={4}   >

										<a href={`https://forms.monday.com/forms/848c3da1e8822302c7403dc35136b096?r=use1&text3=${userObj.user.displayName}`}>Get Support</a>


									</Grid>
									<Grid
										item
										md={4}>
										<Typography variant="h6">{userObj.user.displayName}</Typography>
									</Grid>
								</Grid>
							</td>
						</tr>
						<tr>
							<td colSpan={2}></td>
						</tr>
					</tbody>
				</table>
			</div>
			{authenticated && <PSPMenu bubbleEvt={bubbleEvt} />}
		</div>
	);
}
