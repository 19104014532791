import React from "react";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { ListItem } from "@material-ui/core";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"; //replaces faMinusCircle

import { useSelector, useDispatch } from "react-redux";
//import {users} from '../../models/user';
import allActions from "../../redux/actions";
import bootstrapFromAPI from "../../bootstrapData";
//import { user } from '../../models/user';

const FieldStaff = (props) => {
	//console.log('Incomming Staff',props.Staff);

	const dispatch = useDispatch();
	const objProject = useSelector((state) => state.ProjectReducer.projectInfo);
	//Handle read only
	const isReadOnly = useSelector((state) => state.ProjectReducer.isReadOnly);

	let users = useSelector((state) => state.DeltekUserListReducer.deltekUserList);

	const staff = () => {
		let tmpUser = users.find((user) => user.userId.toLowerCase() === props.Staff.userId.toLowerCase());
		if (!tmpUser) {
			tmpUser = {
				fullName: props.Staff.fullName + " (terminated employee)",
				userId: props.Staff.userId,
			};
		}
		return tmpUser;
	};

	const removeStaff = async () => {

		console.log('removing field staff', props.Staff)
		objProject.projectUsers = objProject.projectUsers.filter(
			(user) => !(user.userId.toLowerCase() === props.Staff.userId.toLowerCase() && user.pspRoleId === 3),
		);
		console.log(objProject)
		await bootstrapFromAPI.removeFieldUser(props.Staff, objProject.wbs1);
		dispatch(allActions.ProjectActions.saved_Project(objProject));

		props.updateUi();
	};

	return (
		<>
			<List dense={true}>
				<ListItem>
					<ListItemText primary={staff().fullName} />
					<ListItemSecondaryAction>
						<IconButton
							edge="end"
							aria-label="delete"
							onClick={removeStaff}
							disabled={isReadOnly}>
							<RemoveCircleIcon style={{ color: "#000000" }} />
						</IconButton>
					</ListItemSecondaryAction>
				</ListItem>
			</List>
		</>
	);
};

export default FieldStaff;
