import ProjectActions from "./projectActions";
import DocumentActions from "./documentActions";
import ActivityLevels from "./activityLevelActions";
import DeltekProjectActions from "./deltekProjectAction";
import AuthenActions from "./authenActions";
import MyPSPActions from "./myPSPActions";
import ExistingWBS1Actions from "./existingWBS1Actions";
import DeltekProjectListActions from "./projectListActions";
import DeltekUserListActions from "./deltekUserActions";

export const allActions = {
	DeltekProjectActions,
	DeltekProjectListActions,
	ProjectActions,
	ActivityLevels,
	DocumentActions,
	AuthenActions,
	MyPSPActions,
	ExistingWBS1Actions,
	DeltekUserListActions,
};

export default allActions;
