import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import GuidanceDocument from "../Control/GuidanceDocument.Component";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TicketScope from "../Ticket/ticketScope";

const ProjectActivity = (props) => {
	const category = props.Category;

	//This logic here to make sure that if we have the odd number or guidance documents
	//the screen will rendor the last document as a full 12 columns
	const oddNumberOfRecords = category.guidanceDocuments.length % 2 === 1 ? true : false;

	return (
		<TicketScope scope="step2-2_activities">
			<Accordion expanded={true}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content">
					<Typography variant="h6">{category.gdcategory}</Typography>
					<br />
				</AccordionSummary>
				<AccordionDetails>
					<Grid
						container
						spacing={0}>
						<Grid
							item
							xs={12}>
							<Typography>
								{category.gdcategory === "General Field Exposures"
									? "The following are typical field exposures, and constitute ESA's standard set of guidance documents for field work. You may unclick GDs that are not applicable to your field work (e.g., alligators/crocodiles) and it will not be included in the ESAfety Plan."
									: "Does your project include any of the following?"}
							</Typography>
						</Grid>
						{category.guidanceDocuments.map((guidanceDocument, index) =>
							oddNumberOfRecords && category.guidanceDocuments.length - 1 === index ? (
								<Grid
									item
									xs={12}
									key={guidanceDocument.gdId}>
									<GuidanceDocument objVal={guidanceDocument} />
								</Grid>
							) : (
								<Grid
									item
									xs={6}
									key={guidanceDocument.gdId}>
									<GuidanceDocument objVal={guidanceDocument} />
								</Grid>
							),
						)}
					</Grid>
				</AccordionDetails>
			</Accordion>
		</TicketScope>
	);
};

export default ProjectActivity;
