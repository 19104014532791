import React from "react";
import Grid from "@material-ui/core/Grid";
import { ResourcesStyles } from "../../Styles";

const Resources = () => {
	const classes = ResourcesStyles();

	return (
		<>
			<div className={classes.root}>
				<br />
				<br />
				<Grid
					container
					spacing={3}>
					<Grid
						item
						xs={1}></Grid>
					<Grid
						item
						xs={10}>
						<Grid
							container
							spacing={1}>
							<Grid
								item
								xs={6}
								className={classes.leftAlign}>
								<img
									src={require("../../images/Home_Icon-Resources_Circle.png")}
									alt="Resources"
								/>
								&ensp;
								<span className={classes.resources}>Resources</span>
							</Grid>
						</Grid>
						<div className={classes.sectionHeader}>GENERAL RESOURCES</div>
						<br />
						<div className={classes.sectionSubHeader}>Project Specific Guidance Documents</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/dhQNDP3Y2Q"
								className={classes.documentLink}>
								Covid-19 Canoe Based Fieldwork
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/2hZvt1n6zR"
								className={classes.documentLink}>
								Covid-19 Total Station Field Procedures
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/Fwa95BizQM"
								className={classes.documentLink}>
								PFRS SeaArk Covid-19 Protocols
							</a>
						</div>
						<br />
						<div className={classes.sectionSubHeader}>SDS for chemicals used as a company</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/dRuBmvRCWH"
								className={classes.documentLink}>
								Isopropyl Alcohol 70%
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/VakVfSTE6Y"
								className={classes.documentLink}>
								Isopropyl Alcohol 95%
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/pNumL56tzx"
								className={classes.documentLink}>
								Bleach
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/rA3hy9Li4J"
								className={classes.documentLink}>
								Tricaine Methanesulfonate (MS-222)
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/sXwLWcnlRl"
								className={classes.documentLink}>
								PVC Pipe Adhesives
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/8aeUmNdY3C"
								className={classes.documentLink}>
								Petroleum Hydrocarbons/Gasoline/Diesel
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/aCUkIETSTV"
								className={classes.documentLink}>
								Benzene, Toluene, Ethylbenzene and Xylene (BTEX)
							</a>
						</div>
						<br />
						<div className={classes.sectionSubHeader}>Boat Operation Checklists</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/MgsTKfWqnR"
								className={classes.documentLink}>
								Boston Whaler
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/M9pQR7z26F"
								className={classes.documentLink}>
								C-Hawk
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/IvPVlZxPfu"
								className={classes.documentLink}>
								Lowe
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/s3r0OF13D0"
								className={classes.documentLink}>
								Monark
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/5OyZBJNacL"
								className={classes.documentLink}>
								North River
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/d2gNXB9chF"
								className={classes.documentLink}>
								Prokat
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/Gh7ZggnZAg"
								className={classes.documentLink}>
								SeaArk
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/rBj8raeOHx"
								className={classes.documentLink}>
								SeaNymph
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://oneesa.egnyte.com/dl/o7JvWh5YZt"
								className={classes.documentLink}>
								Canoes, Kayaks, and Zodiac
							</a>
						</div>
						<br />

						<div className={classes.sectionHeader}>OTHER RESOURCES</div>
						<br />
						<div className={classes.documentLink}>
							<a
								href="https://www.redcross.org/get-help/how-to-prepare-for-emergencies/mobile-apps.html"
								className={classes.documentLink}
								target="_blank"
								rel="noopener noreferrer">
								American Red Cross First Aid App
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								href="http://inside.esa.local/safety"
								className={classes.documentLink}
								target="_blank"
								rel="noopener noreferrer">
								Intranet Safety Page
							</a>
						</div>
						<br />

						<div className={classes.sectionSubHeader}>De-escalation Resources</div>
						<div className={classes.documentLink}>
							<a
								href="https://oneesa.egnyte.com/dl/WjU0Mp1bQL"
								className={classes.documentLink}
								target="_blank"
								rel="noopener noreferrer">
								De-escalation Techniques
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								href="https://oneesa.egnyte.com/dl/xTeVtKR4Og"
								className={classes.documentLink}
								target="_blank"
								rel="noopener noreferrer">
								De-Escalation Tips
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								href="https://oneesa.egnyte.com/dl/YnrXqX0gR3"
								className={classes.documentLink}
								target="_blank"
								rel="noopener noreferrer">
								Five Ways to De-escalate a Conflict
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								href="https://oneesa.egnyte.com/dl/xMEM3LLoHS"
								className={classes.documentLink}
								target="_blank"
								rel="noopener noreferrer">
								Safe Fieldwork and Strategies for At-Risk Indiviuals, their Supervisor and Institutions
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								href="https://oneesa.egnyte.com/dl/oC2kUcPqm4"
								className={classes.documentLink}
								target="_blank"
								rel="noopener noreferrer">
								Situational Awarenwss and De-escalation in the Field
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								href="https://oneesa.egnyte.com/dl/dVIpF2BkVz"
								className={classes.documentLink}
								target="_blank"
								rel="noopener noreferrer">
								Ten Steps to Protect BIPOC Scholars in the Field
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								href="https://oneesa.egnyte.com/dl/JInRjLCbL4"
								className={classes.documentLink}
								target="_blank"
								rel="noopener noreferrer">
								The 5 Ds of Bystander Intervention
							</a>
						</div>

						<br />

						<div className={classes.sectionSubHeader}>Example Completed PSPs</div>
						<div className={classes.documentLink}>
							<a
								href="https://oneesa.egnyte.com/dl/Ujwfh2mjWB"
								className={classes.documentLink}
								target="_blank"
								rel="noopener noreferrer">
								I-40 Median Regrade Project
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								href="https://oneesa.egnyte.com/dl/81l8LTOs0M"
								className={classes.documentLink}
								target="_blank"
								rel="noopener noreferrer">
								Wildhaven Yosemite
							</a>
						</div>
						<div className={classes.documentLink}>
							<a
								href="https://oneesa.egnyte.com/dl/acUHr87HM4"
								className={classes.documentLink}
								target="_blank"
								rel="noopener noreferrer">
								Lake Tapps Aquatic Plant Management 2021
							</a>
						</div>
					</Grid>
					<Grid
						item
						xs={1}></Grid>
				</Grid>
			</div>
		</>
	);
};

export default Resources;
