import { Chip } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import React, { useContext, useEffect } from "react";
import jwt from "jwt-decode";
import { Consumer as KeycloakConsumer } from "react-keycloak-context";
import storage from "redux-persist/lib/storage";

export function keycloakRole(kc, roleName) {
  if (!kc || !roleName) return false;

  const roleArray = kc.resourceAccess["esafety-ui"].roles;
  if (!roleArray) return false;
  let result = roleArray.indexOf(roleName) > -1;
  return result;
}

export async function checkRole(context, roleName) {
	let result = await hasRole(context, "esafety-ui", roleName);
	return result;
}

export function HasRole_t(roleName) {
	const kc = useContext(KeycloakConsumer);
	var ret = hasRole(kc, "esafety-ui", roleName);

	return ret;
}
export const getJSONToken = async () => {
	return new Promise((resolve) => {
		storage.getItem("token").then((token) => {
			let parsed = jwt(token);

			resolve(parsed);
		});
	});
};
export async function hasRole(keycloakContext, client, roleName) {
	/*  client=client.toLowerCase();
     if(!keycloakContext){
       //console.log('---------- CONTEXT UNDEFINED-----------')
     
         return false;
     }
     if(!keycloakContext.tokenParsed)
     {
       //console.log('---------- TOKEN NOT PARSED-----------')
         return false;
     }
     if(!keycloakContext.tokenParsed.resource_access[client])
     {
        //console.log('---------- CLIENT NOT FOUND-----------')
      // //console.log('client not found')
         return false;
     } */

	return new Promise((resolve) => {
		getJSONToken().then((token) => {
			if (!token) return false;
			var roleArray = token.resource_access[client].roles;
			if (!roleArray) return false;
			let result = roleArray.indexOf(roleName) > -1;
			//console.log(`!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!${roleName} => ${result}`)
			resolve(result);
		});
	});
}
export function getProjects(keycloakContext) {
	////console.log('Getting clinet list')
	if (!keycloakContext) {
		// ////console.log('---------- CONTEXT UNDEFINED-----------')
		return false;
	}
	if (!keycloakContext.tokenParsed) {
		//////console.log('---------- TOKEN NOT PARSED-----------')
		return false;
	}
	var ret = [];
	for (const prop in keycloakContext.tokenParsed.resource_access) ret.push(prop);
	return ret;
}

///Shows content if the user has the role, if not does not.
export const HasRole = (props) => {
	const [allowed, setAllow] = React.useState(false);
	useEffect(() => {
		hasRole("", "esafety-ui", props.role).then((result) => {
			console.log('Setting result to ', result)
			setAllow(result);
		}, []);
	});

	return allowed ? (
		<>{props.children}</>
	) : props.errorMessage ? (
		<Chip
			variant={"outlined"}
			avatar={
				<Error
					color={"error"}
					style={{ fontSize: 60 }}
				/>
			}
			label={<>{props.errorMessage}</>}
		/>
	) : (
		<></>
	);
};

export default HasRole;
