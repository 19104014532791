const fetch_Documents = () => {
	return {
		type: "FETCH_DOCUMENTS",
	};
};

const received_Documents = (documents) => {
	return {
		type: "RECEIVED_DOCUMENTS",
		payload: documents,
	};
};

const fetch_Documents_Error = () => {
	return {
		type: "FETCH_DOCUMENTS_ERROR",
	};
};

const documentActions = {
	fetch_Documents,
	received_Documents,
	fetch_Documents_Error,
};

export default documentActions;
