/*
    Author : Peter Ross
    Description : An example of how to handle setting and getting value from redux on a control
            - take away - 
                Don't think of forms, think of controls and values. We're setting state in individual controls 
                rather than evaluating forms as a whole. This makes the controls completely reuseable and not
                reliant on global methods that evaluate an entire form.
*/
import React, { useState, useEffect, useCallback } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../redux/actions";

function arrayRemove(arr, value) {
	return arr.filter(function (ele) {
		return ele !== value;
	});
}

export default function ActivityLevel({ disabled, objVal, classes }) {
	const dispatch = useDispatch();
	const [showText, setShowText] = useState(false);
	const objProject = useSelector((state) => state.ProjectReducer.projectInfo);
	const isReadOnly = useSelector((state) => state.ProjectReducer.isReadOnly);

	const isChecked = useCallback(() => {
		return objProject.projectActivityLevels
			? objProject.projectActivityLevels.find((al) => al.activityLevelId === objVal.activityLevelId) !== undefined
			: [];
	}, [objProject, objVal]);

	useEffect(() => {
		setShowText(isChecked());
	}, [isChecked]);

	const handleChange = (event) => {
		if (event.target.checked) {
			objProject.projectActivityLevels.push({
				activityLevelId: objVal.activityLevelId,
				noFieldVisit: objVal.noFieldVisit,
			});
			setShowText(true);

			if (objVal.requires !== null) {
				if (objProject.projectActivityLevels.find((al) => al.activityLevelId === objVal.requires) === undefined)
					objProject.projectActivityLevels.push({
						activityLevelId: objVal.requires,
						noFieldVisit: objVal.noFieldVisit,
					});
			}

			if (objVal.activityLevelId !== 10) {
				objProject.projectActivityLevels = objProject.projectActivityLevels.filter(
					(activity) => activity.activityLevelId !== 10,
				);
			}
		} else {
			objProject.projectActivityLevels = arrayRemove(
				objProject.projectActivityLevels,
				objProject.projectActivityLevels.find((al) => al.activityLevelId === objVal.activityLevelId),
			);
			setShowText(false);
		}

		if (event.target.checked && objVal.noFieldVisit) {
			objProject.projectActivityLevels = objProject.projectActivityLevels.filter(
				(activity) => activity.noFieldVisit === true,
			);
		}

		//Commented out the next block to resolve issue SAFE-150, but not sure if this will cause any other issue.
		if (event.target.checked && objVal.noFieldVisit === false) {
			console.log("No field visit = false", objProject.projectActivityLevels);
			//objProject.projectActivityLevels = objProject.projectActivityLevels.filter(activity => activity.noFieldVisit === false);
		}

		dispatch(allActions.ProjectActions.saved_Project(objProject));
	};

	return (
		<>
			<FormControlLabel
				control={
					<Checkbox
						onClick={handleChange}
						name={`activityLevelId` + objVal.activityLevelId}
						color="secondary"
						checked={isChecked()}
						disabled={disabled || isReadOnly}
					/>
				}
				label={<Typography variant="body2">{objVal.activityLevelName}</Typography>}
			/>
			<br />
			{showText && objVal.activityLevelDescription ? (
				<div className={classes.activityDescription}>{objVal.activityLevelDescription}</div>
			) : (
				""
			)}
			<hr />
		</>
	);
}
