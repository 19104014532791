import React, { useEffect, useState } from "react";
import { GridToolbar, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Snackbar, Button, Switch, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import bootstrapData from "../../../bootstrapData";

const theme = createTheme({
    palette: {
        secondary: {
            main: "#004361",
        },
        primary: {
            main: "#1976d2",
        },
    },
    typography: {
        h6: {
            fontWeight: 600,
        },
        body1: {
            fontWeight: 500,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: 8,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 16,
                },
            },
        },
    },
});

const regionOptions = [
    "All Regions",
    "North West",
    "Southern California",
    "Northern California",
    "South East",
    "Data Tech"
];

export const ManageCoordinators = () => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [coordinators, setCoordinators] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentCoordinator, setCurrentCoordinator] = useState(null);

    useEffect(() => {
        document.title = "Manage Coordinators";
        bootstrapData.getCoordinators().then((data) => {
            setCoordinators(data);
        });
    }, []);

    const handleAddCoordinator = (newCoordinator) => {
        bootstrapData.addCoordinator(newCoordinator).then(() => {
            setSnackbarMessage("Coordinator added successfully");
            setOpenSnackbar(true);
            bootstrapData.getCoordinators().then((data) => {
                setCoordinators(data);
            });
        });
    };

    const handleUpdateCoordinator = (updatedCoordinator) => {
        bootstrapData.updateCoordinator(updatedCoordinator).then(() => {
            setSnackbarMessage("Coordinator updated successfully");
            setOpenSnackbar(true);
            bootstrapData.getCoordinators().then((data) => {
                setCoordinators(data);
            });
        });
    };

    const handleDeleteCoordinator = (coordinatorId) => {
        bootstrapData.deleteCoordinator(coordinatorId).then(() => {
            setSnackbarMessage("Coordinator deleted successfully");
            setOpenSnackbar(true);
            bootstrapData.getCoordinators().then((data) => {
                setCoordinators(data);
            });
        });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleSwitchChange = (event, params) => {
        const { field } = params;
        const updatedCoordinator = { ...params.row, [field]: event.target.checked };
        handleUpdateCoordinator(updatedCoordinator);
    };

    const handleSelectChange = (event, params) => {
        const updatedCoordinator = { ...params.row, regionText: event.target.value };
        handleUpdateCoordinator(updatedCoordinator);
    };

    const handleProcessRowUpdate = (newRow, oldRow) => {
        handleUpdateCoordinator(newRow);
        return newRow;
    };

    const handleEditClick = (params) => {
        setCurrentCoordinator(params.row);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setCurrentCoordinator(null);
    };

    const handleDialogSave = () => {
        handleUpdateCoordinator(currentCoordinator);
        setDialogOpen(false);
    };

    const handleDialogChange = (field, value) => {
        setCurrentCoordinator((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const columns = [
        { field: "id", headerName: "ID", hide: true, sortable: true },
        { field: "friendName", headerName: "Name", width: 200, editable: true, sortable: true },
        { field: "userName", headerName: "Email", width: 200, editable: true, sortable: true },
        {
            field: "regionText",
            headerName: "Region",
            width: 200,
            editable: true,
            sortable: true,
            renderCell: (params) => (
                <Select
                    value={params.value}
                    onChange={(event) => handleSelectChange(event, params)}
                    fullWidth
                >
                    {regionOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            ),
        },
        {
            field: "SafetyOfficer",
            headerName: "Safety Officer",
            width: 200,
            editable: true,
            sortable: true,
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    onChange={(event) => handleSwitchChange(event, params)}
                />
            ),
        },
        {
            field: "PSPCoordinator",
            headerName: "PSP Coordinator",
            width: 200,
            editable: true,
            sortable: true,
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    onChange={(event) => handleSwitchChange(event, params)}
                />
            ),
        },
        {
            field: "isAdmin",
            headerName: "Admin",
            width: 200,
            editable: true,
            sortable: true,
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    onChange={(event) => handleSwitchChange(event, params)}
                />
            ),
        },
        {
            field: "sendEmails",
            headerName: "Send Emails",
            width: 200,
            editable: true,
            sortable: true,
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    onChange={(event) => handleSwitchChange(event, params)}
                />
            ),
        },
        {
            field: "canApprove",
            headerName: "Can Approve",
            width: 200,
            editable: true,
            sortable: true,
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    onChange={(event) => handleSwitchChange(event, params)}
                />
            ),
        },
        { field: "lastUpdated", headerName: "Last Updated", width: 200, editable: true, sortable: true },
        {
            field: "actions",
            headerName: "Actions",
            type: "actions",
            sortable: false,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => handleDeleteCoordinator(params.id)}
                />,
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    onClick={() => handleEditClick(params)}
                />,
            ],
        },
    ];

    return (
        <ThemeProvider theme={theme}>
            <DataGridPro
                rows={coordinators}
                columns={columns}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            isAdmin: false,
                            sendEmails: false,
                            lastUpdated: false,
                            id: false,
                        },
                    },
                }}
                components={{
                    Toolbar: GridToolbar,
                }}
                filterModel={{
                    items: [
                        { columnField: "regionText", operatorValue: "contains", value: "" },
                        { columnField: "friendName", operatorValue: "contains", value: "" },
                        { columnField: "SafetyOfficer", operatorValue: "=", value: "" },
                        { columnField: "PSPCoordinator", operatorValue: "=", value: "" },
                        { columnField: "isAdmin", operatorValue: "=", value: "" },
                        { columnField: "sendEmails", operatorValue: "=", value: "" },
                        { columnField: "canApprove", operatorValue: "=", value: "" },
                    ],
                }}
                sortModel={[
                    { field: "regionText", sort: "asc" },
                    { field: "friendName", sort: "asc" },
                    { field: "userName", sort: "asc" },
                    { field: "SafetyOfficer", sort: "asc" },
                    { field: "PSPCoordinator", sort: "asc" },
                    { field: "isAdmin", sort: "asc" },
                    { field: "sendEmails", sort: "asc" },
                    { field: "canApprove", sort: "asc" },
                    { field: "lastUpdated", sort: "asc" },
                ]}
                sortingOrder={['asc', 'desc']}
                disableMultipleColumnsSorting={false}
                pagination
                pageSize={10}
                checkboxSelection
                processRowUpdate={handleProcessRowUpdate}
                onProcessRowUpdateError={(error) => console.error(error)}
                componentsProps={{
                    toolbar: {
                        density: "compact",
                        columns: ["filter", "columns", "export"],
                    },
                }}
                experimentalFeatures={{ newEditingApi: true }}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    const newCoordinator = {
                        userName: `nCoordinator${coordinators.length}@esassoc.com`,
                        regionText: "All",
                        friendName: `New Coordinator ${coordinators.length + 1}`,
                        SafetyOfficer: false,
                        PSPCoordinator: false,
                        isAdmin: false,
                        sendEmails: false,
                        canApprove: false,
                        lastUpdated: new Date().toISOString(),
                    };
                    handleAddCoordinator(newCoordinator);
                }}
            >
                Add Coordinator
            </Button>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Edit Coordinator</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Name"
                        value={currentCoordinator?.friendName || ''}
                        onChange={(e) => handleDialogChange('friendName', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Email"
                        value={currentCoordinator?.userName || ''}
                        onChange={(e) => handleDialogChange('userName', e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <Select
                        label="Region"
                        value={currentCoordinator?.regionText || ''}
                        onChange={(e) => handleDialogChange('regionText', e.target.value)}
                        fullWidth
                        margin="dense"
                    >
                        {regionOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                    <div style={{ marginTop: 16 }}>
                        <Switch
                            checked={currentCoordinator?.SafetyOfficer || false}
                            onChange={(e) => handleDialogChange('SafetyOfficer', e.target.checked)}
                        /> Safety Officer
                    </div>
                    <div>
                        <Switch
                            checked={currentCoordinator?.PSPCoordinator || false}
                            onChange={(e) => handleDialogChange('PSPCoordinator', e.target.checked)}
                        /> PSP Coordinator
                    </div>
                    <div>
                        <Switch
                            checked={currentCoordinator?.isAdmin || false}
                            onChange={(e) => handleDialogChange('isAdmin', e.target.checked)}
                        /> Admin
                    </div>
                    <div>
                        <Switch
                            checked={currentCoordinator?.sendEmails || false}
                            onChange={(e) => handleDialogChange('sendEmails', e.target.checked)}
                        /> Send Emails
                    </div>
                    <div>
                        <Switch
                            checked={currentCoordinator?.canApprove || false}
                            onChange={(e) => handleDialogChange('canApprove', e.target.checked)}
                        /> Can Approve
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDialogSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};
